/*form blue: #142740*/

.Contact-main-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 53px);
	display: flex;
	flex-direction: column;
}

.Contact-Headerbanner-container {
	width: 100%;
	height: 495px;
	background-image: 
		linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.35)),
		url("https://www.digitechsystems.com/wp-content/uploads/2024/07/contact-section-wr.webp");
	background-size: cover;
	background-repeat: no-repeat;
	color: #000001;
    display: flex;
    align-items: center;
    border-bottom: 6px solid #062347;
	background-position: center center;
}


@media screen and ( max-width: 599px ) and ( min-width: 350px ) {
	
	.Contact-Headerbanner-container {
	width: 100%;
	height: 495px;
	background-image: 
		linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.65)),
		url("https://www.digitechsystems.com/wp-content/uploads/2024/07/contact-section-wr.webp");
	background-size: cover;
	background-repeat: no-repeat;
	color: #000001;
    display: flex;
    align-items: center;
    border-bottom: 6px solid #062347;
	background-position: center center;
}	
	
    }

.Contact-topform-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.Contact-topform-container h2 {
	color: #000001;
	font-size: var(--media-xl-font-size);
	width: 65%;
}

.Contact-topform-container p {
	color: #000001;
	font-size: var(--media-s-font-size);
	width: 50%;
}

.Contact-welcome-container {
	width: 100%;
	display: flex;
	justify-content: space-around;
	padding: 25px 0px;
	background: #D4E3F5;
	color: #000001;
	flex-direction: column;
	align-items: center;
	border-bottom: 6px solid #062347;
}
.Contact-welcome-subcontainer {
	width: 86%;
/*	padding: 20px;*/
}
.Contact-welcome-leftsub {
    background-size: cover;
    background-repeat: no-repeat;
    border: 6px solid #062347;
}
.Contact-welcome-subcontainer h3 {
	font-size: 1.8em;
}
.Contact-welcome-subcontainer ul {
	margin-left: 14px;
    margin-top: 8px;
}
.Contact-subcontainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
/*	background: #1263C7;*/
	border-bottom: 6px solid #062347;
}
.Contact-form-container {
	display: flex;
	flex-direction: column;
	height: 433px;
}
.Contact-form-container input {
    margin: 5px 0;
}
.Contact-container {
	width: 282px;
	color: #000001;
	margin: 25px 0;
}
.Contact-right-container {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/contact-subbanner.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
	color: #000001;
	width: 50%;
	height: 100%;
	display: flex;
    justify-content: center;
    align-items: center;
}
.Contact-left-container {
	width: 50%;
}

.form-wrapper-container {
    display: flex;
	flex-direction: column;
	justify-content: center;
}

.Contact-iframe-container {
	height: 650px;
	width: 338px;
    font-family: 'Roboto', sans-serif;
	border: none !important;
}
.Contact-right-container h4 {
	font-weight: 500;
    font-size: 1.5em;
	margin-bottom: 9px;
}
.Contact-right-container h5 {
	font-weight: 500;
    font-size: 1.1em;
    margin-top: 5px;
}
.Contact-right-container p {
	font-weight: 300;
    font-size: .9em;
}
.Contact-form-input {
	display: flex;
	flex-direction: column;
}

.Contact-ask-input {
	width: 70%;
	height: 180px;
}
.Contact-ask-button {
	background-color: #F16E12;
    padding: 12px 50px;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    margin-top: 25px;
    max-width: 175px;
}

.contact-sales-title {
	margin-top: 30px;
}
.Contact-welcome-rightsub {
	flex-direction: row;
	display: flex;
	justify-content: space-between;
	padding: 20px;
}
.Contact-welcome-rightsub h3 {
	font-size: var(--media-l-font-size);
	font-weight: 500;
	line-height: 1.3;
	margin-bottom: 6px;
}
.Contact-welcome-rightsub p {
	font-size: var(--media-s-font-size);
    line-height: 1.3;
    font-weight: 300;
	margin: 7px 0;
}
.Contact-welcome-rightsub ul {
	font-size: var(--media-s-font-size);
    line-height: 1.3;
    font-weight: 300;
}
.Contact-welcome-op {
}

.Contact-welcome-textcontainer {
	width: 48%;
}

.Contact-welcome-textcontainer ul {
	margin-left: 20px;
}

.contact-link {
	text-decoration: none;
	color: #000001;
}

.Contact-iframe-container {
	border: none !important;
}

.Contact-iframe-container {
	width: 550px;
	height: 600px;
    margin: 0 auto;
	
}

.Form-wrapper-container {
	margin: 0 auto;
	width: 95%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 0px !important;
}

.Form-wrapper-h4 {
	color: #212529;
	width: 325px;
	font-size: var(--media-m-font-size) !important;
	margin-left: 20px;
}

.Contact-group-img {
	width: 75%;
	border-radius: 4px;
}

@media screen and (max-width: 980px) and (min-width: 650px) {
	
	.Contact-welcome-rightsub h3 {
		font-size: var(--media-m-font-size);
	}
	.Contact-welcome-rightsub p {
		font-size: var(--media-s-font-size);
	}
	
	.Contact-welcome-rightsub ul {
		font-size: var(--media-s-font-size);
	}
	
	.Contact-subcontainer {
		flex-direction: column;
	}
	.Contact-left-container {
		width: 100%;
		
	}
	.Contact-right-container {
		width: 100%;
/*		height: 100%;*/
	}
	
	.Contact-welcome-subcontainer {
		
	}
	
	.Contact-welcome-leftsub {
		background-size: 100%;
	}
	
	
	
}

 
@media screen and (max-width: 649px) and (min-width: 300px) {
	
	.Contact-left-container {
        width: 100%;
	}
	.Contact-right-container {
        width: 100%;
	}
	
	.Contact-subcontainer {
    	flex-direction: column;
    }
	
	.Contact-welcome-rightsub {
  		flex-direction: column;
	}
	
	.Contact-welcome-textcontainer {
        width: 100%;
		margin-bottom: 12px;
	}
	
	.Contact-welcome-rightsub p {
  		font-size: var(--media-s-font-size);
	}
	
	.Contact-welcome-rightsub h3 {
  		font-size: var(--media-l-font-size);
		margin-top: 10px;
	}
	
	.Contact-welcome-leftsub {
	    margin-left: 0;
		margin-bottom: 30px;
		width: 85%;
        border: 3px solid #062347;
	}
	
	.Contact-Headerbanner-container {
		height: 385px;
	}
	
	.Contact-form-container {
		flex-direction: column-reverse;
  		background: #1263C7;
	}
	
	.Contact-welcome-rightsub ul {
  		font-size: var(--media-s-font-size);
	}
	
	.Contact-welcome-container {
		padding: 0;
		padding: 20px 0 0 0;
	}

	.Contact-iframe-container {
		width: 337px;
    	height: 603px;
		margin-left: 0;
	}
	
	.Form-wrapper-container {
		width: 80%;
		margin: 0 auto;
	}
	
	.Ebook-whatsincluded-left h4 {
      	padding-top: 20px;
	}
	
	.Ebook-whatsincluded-left h4 {
  		padding-top: 20px;
	}
	
}

@media screen and (max-width: 500px) and (min-width: 350px) {
	
	.Contact-iframe-container {
		width: 100%;
		height: 618px;
	}
	
	.Form-wrapper-h4 {
		margin-top: 35px;
		color: #212529;
    }

}