@charset "utf-8";
/* CSS Document */

.promo-sign-in {
	margin: 35px;
	text-decoration: none;
	color: #e6e6e6;
	font-weight: 500;
	font-size: var(--media-s-font-size);
}

.Products-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 53px);
	display: flex;
	flex-direction: column;
}

.Padding-block {
  width: 100%;
  height: 53px;
}

.Products-welcome-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	background-image: 
		linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.35)),
		url("https://s3.us-east-1.amazonaws.com/www.info.sys.tm/learn-section-wr-6.png");
	background-size: cover;
  	background-repeat: no-repeat;
	background-position: center right;
	border-bottom: 6px solid #062347;
}

.Products-main-left {
	width: 55%;
	display: flex;
    flex-direction: column;
	justify-content: center;
	padding-left: 50px;
/*	height: 500px;*/
}

.Products-main-left h1 {
    font-size: var(--media-xl-font-size);
    line-height: 1.3;
	font-weight: 500;
}

.Products-main-left p {
	margin-top: 5px;
	font-weight: 300;
    line-height: 1.4;
	width: 65%;
	max-width: 535px;
	font-size: var(--media-m-font-size);
	margin-bottom: 25px;
}

@media screen and ( max-width: 1200px ) and ( min-width: 800px ) {
	
	.Products-main-left h1 {
		font-size: var(--media-l-font-size);
	}
	
	.Products-main-left p {
		font-size: var(--media-s-font-size);
	}
	
}

@media screen and ( max-width: 799px ) and ( min-width: 350px ) {
	
	.Products-main-left h1 {
		font-size: var(--media-l-font-size);
	}
	
	.Products-main-left p {
		font-size: var(--media-s-font-size);
	}
	
}

@media screen and ( max-width: 599px ) and ( min-width: 350px ) {
	
		.Products-welcome-container {
			width: 100%;
			display: flex;
			flex-direction: row;
	         background-image: 
		      linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.65)),
		      url("https://s3.us-east-1.amazonaws.com/www.info.sys.tm/uploads/learn-section-wr-6.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAR7573XVKWTTBXLFR/20250321/us-east-1/s3/aws4_request&X-Amz-Date=20250321T170228Z&X-Amz-Expires=604800&X-Amz-Signature=e4aa37186adf01629d685b18a6f86d921c852ae7332feb501f0c5f275cd1d9b9&X-Amz-SignedHeaders=host&response-content-disposition=inline");
			background-size: cover;
  			background-repeat: no-repeat;
			background-position: center right;
			border-bottom: 6px solid #062347;
		}
	
    }

.Learn-button-container {
	width: 340px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

@media screen and ( max-width: 600px ) and ( min-width: 350px ) {
	
	.Learn-button-container {
	   flex-direction: column;
	   margin-bottom: 15px;
	   height: 90px;
	   width: 193px;
		margin-left: 20px;
    }
	
	
	
	
}

.Ready-button-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

@media screen and ( max-width: 600px ) and ( min-width: 350px ) {
	
	.Ready-button-container {
	  flex-direction: column;
	  width: 250px;
      align-items: center;
      height: 97px;
    }
	
}

.Products-main-right {
	width: 30%;
	height: 480px;
}

.Products-right-image {
	border: 7px solid #1263C7;
	width: 65%;
}

.Products-main-right iframe {
	border: 5px solid #1263C7;
}

.Learn-press-release {
	width: 100%;
	display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 6px solid #062347;
    padding: 25px 0px;
	background: #f16e12;
	color: #062347;
	text-decoration: none !important;
}

.Learn-press-release:hover {
	background: #dc630d;
}

.Learn-press-release h3 {
	width: 50%;
	max-width: 600px;
    text-align: center;
    font-weight: 400;
    font-size: var(--media-m-font-size);
    line-height: 1.3;
}

@media screen and ( max-width: 1150px ) and (min-width: 350px) {
	
	.Learn-press-release h3 {
		width: 75%;
	}
}

@media screen and ( max-width: 1150px ) and (min-width: 350px) {
	
	.Learn-press-release h3 {
		width: 75%;
	}
}


.Learn-press-release a {
	text-decoration: none !important;
}

.Products-bigpicture-container {
	width: 100%;
	background: #D4E3F5;
	border-bottom: 6px solid #062347;
	display: flex;
	align-items: center;
	padding: 15px 0;
}

.Products-bigpicture-itemcontainer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 95%;
    margin: 0 auto;
}

.Products-bigpicture-items {
	width: 22%;
	background-color: #BED8F9;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 20px;
	border-radius: 5px;
	border: 2px solid #062347;
	height: 181px;
	margin: 8px 0px;
}

.learn-hidden-h3 {
	text-align: center;
	font-weight: 500;
	font-size: var(--media-l-font-size);
	margin: 10px 0 5px 0;
}

.Products-bigpicture-text h4 {
	font-weight: 500;
    font-size: var(--media-s-font-size);
    line-height: 1.2;
	margin-bottom: 5px;
}
.Products-bigpicture-text p {
	font-weight: 300;
	font-size: var(--media-xs-font-size);
	line-height: 1.3;
} 
.Products-bigpicture-imgblock {
	margin-bottom: 24px;
	background-size: contain;
  	background-repeat: no-repeat;
/*
	width: 75px;
	height: 75px;
*/
	background-position: center;
}
.Products-bigpicture-intro {
	background: #D4E3F5;
	color: #ffffff;
	width: 15%;
	border: none;
	margin-left: 25px;
	display: flex;
    justify-content: center;
}
.Products-bigpicture-intro-hidden {
	background: none;
	display: none;
	justify-content: center;
	align-items: center;
	border: none;
}
.Products-bigpicture-intro h3 {
	color: rgb(0, 0, 1);
    font-size: var(--media-l-font-size);
    font-weight: 500;
	line-height: 1.3;
}
.Products-bigpicture-intro p {
	color: rgb(0, 0, 1);
}
.bigpicture-imgblock-a {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/Cloud-server-blue.png");
	width: 75px;
    height: 75px;
}
.bigpicture-imgblock-b {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/goverment-building-blue.png");
	width: 68px;
    height: 68px;
}
.bigpicture-imgblock-c {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/sys-cloud-security-blue.png");
	width: 65px;
	height: 65px;
}

.Products-bigpicture-a:Hover {
	border: 2px solid #062347;
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
}
.Products-bigpicture-b:Hover {
	border: 2px solid #062347;
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
}
.Products-bigpicture-c:Hover {
	border: 2px solid #062347;
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
}
.Products-bigpicture-a:Hover .bigpicture-imgblock-a {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/04/Cloud-server-ow.png");
}
.Products-bigpicture-b:Hover .bigpicture-imgblock-b {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/goverment-building-active.png");
}
.Products-bigpicture-c:Hover .bigpicture-imgblock-c {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/sys-cloud-security-active.png");
}


.maninfo-imgblock-a {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/sq-windows-lightblue.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  background-position: center;
}

.product-benefit-top:hover .maninfo-imgblock-a {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/sq-windows-active.png");
}

.maninfo-imgblock-b {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/sq-work-blue.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 85px;
  height: 85px;
  background-position: center;
}

.product-benefit-b:hover .maninfo-imgblock-b {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/sq-work-active.png");
}

.maninfo-imgblock-c {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/sq-lock-lightblue.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 85px;
  height: 85px;
  background-position: center;
}

.maninfo-imgblock-d {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/systm/productivity-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 85px;
  height: 85px;
  background-position: center;
}

.product-benefit-d:hover .maninfo-imgblock-d {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/systm/productivity-icon-active.png");
}

.product-benefit-c:hover .maninfo-imgblock-c {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/sq-lock-active.png");
}

.Products-industry-container {
	width: 100%;
	height: 105px;
	background-color: #1263C7;
	color: #ffffff;
    line-height: 1.4;
	display: none;
}

.Products-industry-container h4 {
		font-size: var(--media-m-font-size);
        width: 9%;
}

.Products-industry-item {
	width: 980px;
}

.Products-industry-item h4 {
		font-size: var(--media-xs-font-size);
		width: 30%;
        text-align: center;
        line-height: 1;
        margin-top: 3px;
}

.Products-industry-iconContainer {
	width: 60px;
	height: 60px;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/light-blue-circ.png");
	background-size: contain;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Products-benefits-maincontainer {
	display: flex;
    flex-direction: column;
	width: 100%;
	border-bottom: 6px solid #062347;
	padding: 30px 0;
}
.Products-benefits-mainh2 {
	margin: 0 auto;
	font-size: var(--media-l-font-size);
	margin-top: 10px;
	font-weight: 500;
}
.Products-benefits-mainp {
	width: 37%;
    text-align: center;
	margin: 0 auto;
	font-weight: 300;
	font-size: var(--media-s-font-size);
	margin-bottom: 10px;
}

.Products-benefits-container {
	width: 100%;
	display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
	color: #ffffff;
	margin: 0 auto;
}

@media screen and ( max-width: 1150px ) and (min-width: 350px) {
	
	.Products-benefits-container {
		flex-direction: column;
	}
	
}


.Products-benefits-left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 35%;
	align-items: flex-end;
	padding: 10px;
}
.Products-benefits-center {
	display: flex;
	flex-direction: column;
	width: 50%;
	align-items: center;
    justify-content: center;
    background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-circs-oncircs.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
	height: 400px;
}
.Products-benefits-topComputer {
	display: none;
	flex-direction: column;
	width: 30%;
	align-items: center;
    justify-content: center;
}
.Products-benefits-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 45%;
	padding: 10px;
	align-items: center;
}

.Products-benefits-image {
	width: 100%;
	max-width: 450px;
}

.Products-benefits-items {
    display: flex;
    flex-direction: row;
	margin: 5px;
	color: #1263C7;
	padding: 10px 20px;
	border-radius: 5px;
    border: 1px solid #062347;
	background: #D4E3F5;
	align-items: center;
	height: 126px;
	width: 500px;
}
.Products-benefits-items:hover {
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
}
    

.benefits-right {

}
.Products-benefits-items h4 {
	font-size: var(--media-m-font-size);
	font-weight: 500;
 	line-height: 1.2;
	margin-top: 5px;
	margin-left: 10px;
	color: #000001;
}
.Products-benefits-items p {
	font-size: var(--media-s-font-size);
    font-weight: 300;
    line-height: 1.3;
	margin-top: 2px;
	margin-left: 13px;
	color: #000001;
}

@media screen and (max-width: 1200px) and (min-width: 350px) {
	
	.Products-benefits-items p {
		font-size: var(--media-xs-font-size);
	}
	
}

.Products-benefits-img {
	
}

.Products-industry-itemContainer {
	display: flex;
	flex-direction: row;
	width: 70%;
	align-items: center;
}

.Products-benefits-icon {
	width: 80px;
    height: 80px;
	background-color: #ffffff;
}
.Products-icon-simplify {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/noun-simplify.png");
	background-size: contain;
	display: flex;
	justify-content: center;
}
.Products-icon-automate {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/automation.png");
	background-size: contain;
	display: flex;
	justify-content: center;
}
.Products-icon-save {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/save-time.png");
	background-size: contain;
	display: flex;
	justify-content: center;
	background-repeat: no-repeat;
}

.Products-icon-secure {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/secure.png");
	background-size: contain;
	display: flex;
	justify-content: center;
}

.Products-value-subcontainer {
	width: 90%;
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	justify-content: center;
	margin-bottom: 35px;
}

.Products-value-item {
	width: 160px;
}

.Products-img-easy {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/Ease-of-use.png");
	background-size: contain;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	width: 125px;
	height: 125px;
}

.Products-img-rich {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/Feature-functionality.png");
	background-size: contain;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	width: 125px;
	height: 125px;
}
.Products-img-value {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/Price-performance.png");
	background-size: contain;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	width: 125px;
	height: 125px;
}
.Products-img-cost {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/Architectural-flexibility.png");
	background-size: contain;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	width: 125px;
	height: 125px;
}

.Products-item-img {
	width: 100%;
	height: 154px;
}

.learn-dl-button {
	padding: 12px 50px;
	border-radius: 5px;
	margin-top: 15px;
	color: #ffffff;
}

.Products-ebook-button p {
	margin-left: none;
}

.Ready-start-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	color: #ffffff;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 50px 0;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-branded-background.png");
    background-size: cover;
    background-repeat: no-repeat;
	border-bottom: 6px solid #062347;
}

.Ready-start-container h2 {
	font-size: var(--media-xl-font-size);
	font-weight: 500;
	color: #ffffff;
	margin-bottom: 30px;
}

.Ready-start-container p {
	font-size: 1.1em;
	width: 38%;
	font-weight: 300;
	color: #ffffff;
}

.Ready-start-button {
	background-color: #F16E12;
	padding: 12px 50px;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    margin-top: 25px;
}

.Products-value-stack {
	display: flex;
	flex-direction: row;
}

.Products-value-subcontainer {
	
}

.Products-HK-container {
	width: 100%;
    background: #D4E3F5;
	display: flex;
	justify-content: center;
	padding: 30px 0;
	flex-direction: row;
	align-items: center;
	border-bottom: 6px solid #062347;
}

.Products-HK-item {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.Products-HK-textcontainer {
	width: 72%;
}

.Learn-HK-img {
	border-radius: 50%;
	margin-right: 20px;
}

.Products-HK-textcontainer h5 {
    font-size: var(--media-m-font-size);
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 10px;
	margin-top: 15px;
}

.Products-HK-textcontainer p {
    font-size: var(--media-xs-font-size);
	font-weight: 600;
	float: right;
}

.Products-signup-container {
	width: 100%;
	display: flex;
	background: #1263C7;
}

.Products-signup-item {
	width: 50%;
	height: 150px;
}

.Products-start-button {
	border: 3px solid #ffffff;
}

.contact-button {
	width: 57px;
	margin-top: 12px;
}

.product-buttons-a {
	width: 156px;
}

.product-buttons-b {
	width: 150px;
}

.Home-contact-items h2 {
	font-size: var(--media-m-font-size);
}

.Home-contact-items p {
	font-size: var(--media-s-font-size);
}

.Contact-container-left {
  width: 100%;
  background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/contact-bottom-wr.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 450px;
}

@media screen and ( max-width: 600px ) and ( min-width: 350px ){
	
	.Contact-container-left {
		height: 250px;
	}
	
}

.Contact-container-right {
	width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #bed8f9;
    height: 450px;
	align-items: center;
}

.Contact-left-textblock {
	width: 85%;
}

.Home-leftbackground-container {
	margin-left: 5px;
	display: flex;
    flex-direction: column;
/*    align-items: center;*/
}

.primary-button {
  height: 65px;
  width: 125px;
  font-size: 16px !important;
  border-radius: 20px !important;
  color: --sys-primary !important;
}

.mat-stroked-button {
  font-weight: 500;
  letter-spacing: .02em;
  height: 39px !important;
  border: .7px solid #141414 !important;
  line-height: 37px !important;
}

.welcome-button {
  font-size: 16px;
  border-radius: 20px;
  background: #ffffff;
  color: #3C6090;
  font-weight: 500;
  display: flex;
  justify-content: center;
  padding: 9px 23px;
  letter-spacing: 0.02em;
  text-decoration: none;
  border: .7px solid #141414;
}

.welcome-button:hover {
  background: #e2e9f2;
}

@media screen and ( max-width: 600px ) and ( min-width: 200px ) {
	
	.Contact-container-right {
		height: 344px;
	}
	
	.Home-contact-iconcontainer {
/*		margin-left: 10px;*/
    }
	
}

.Home-products-container {
	width: 100%;
  display: flex;
  color: #000001;
  border-bottom: 6px solid #062347;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}

.Products-container-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000001;
  height: 235px;
  align-items: center;
  width: 45%;
}

.products-container-right {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-circs-oncircs.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and ( max-width: 1270px ) {
	
	.Products-benefits-items p {
		font-size: --media-xs-font-size;
	}
	.Products-benefits-items {
		height: 120px;
	}
	.Products-benefits-mainp {
		width: 48%;
        font-size: --media-s-font-size;
       font-weight: 300;
	}
	
}

@media screen and ( max-width: 1269px ) and (min-width: 300px) {
  
	.Products-benefits-items {
	}
	.Products-benefits-items h4 {
		font-size: --media-s-font-size;
	}
	.Products-bigpicture-intro {
		display: none;
	}
	.Products-bigpicture-intro-hidden {
		display: flex;
        margin: 0 auto;
	}
	.Products-bigpicture-itemcontainer {
		width: 93%;
		padding: 10px 10px;
	}
	.Products-benefits-mainh2 {
    	margin: 0 auto;
   		margin-top: 20px;
	}
	.bigpicture-items-container {
		width: 95%;
	}
	.Products-bigpicture-items {
		width: 27%;
	}
	
	.Products-bigpicture-container {
		flex-direction: column;
	}
	
}


@media screen and ( max-width: 984px ) and (min-width: 601px) {
	
	.Products-benefits-mainh2 {
 		 margin: 0 auto;
    	 margin-top: 40px;
  	 	font-size: --media-l-font-size;
	}
	  .Products-benefits-mainp {
      	font-size: --media-s-font-size;
	}
	
	
	
	.Products-benefits-left {
		width: 70%;
		margin: 10px 5px;
		align-items: center;
		height: 342px;
	}
	
	.Products-benefits-right {
		width: 70%;
		height: 500px;
	}
	
	.Products-value-subcontainer {
		flex-direction: column;
	}
	
	.Products-value-stack {
		justify-content: space-around;
	}
	
	.Products-benefits-items {
		height: 139px;
		width: 90%;
		flex-direction: row;
		align-items: center;
	}
	
	.Products-bigpicture-intro {
		display: none;
	}
	
	.learn-hidden-h3 {
       font-size: 20px !important;
       width: 65%;
       text-align: center;
	}
	
	.Products-HK-textcontainer h5 {
		font-size: --media-s-font-size;
	}
	
	.Products-benefits-center {
    	width: 65%;
	}
	
	.benefit-display {
		display: none;
	}
	.benefit-display-none {
		display: flex;
	}
	.Products-benefits-topComputer {
		width: 60%;
		height: 300px;
		margin-bottom: 5px;
	}
	
	.Products-benefits-left {
    	height: 550px;
    }
	
	
	.Products-benefits-mainp {
       width: 62%;
  }
	
	.Products-HK-item h5 {
		font-size: --media-s-font-size;
/*		text-align: center;*/
	}
	
	.Ready-start-container p {
		width: 65%;
		line-height: 1.3;
	}
	
	.Products-bigpicture-itemcontainer {
  		width: 91%;
	}
	
	.Products-bigpicture-items {
		width: 24% !important;
		margin: 4px 0;
		height: 218px;
	}
	.Products-bigpicture-intro {
		display: none;
	}
	.Products-bigpicture-intro-hidden {
		display: flex;
        padding-top: 15px;
	}
	.Products-bigpicture-text h4 {
		font-size: --media-s-font-size;
	}

}

.Products-info-container {
	width: 100%;
  display: flex;
  color: #000001;
  border-bottom: 6px solid #062347;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 20px 0;
}

@media screen and ( max-width: 1200px ) and ( min-width: 350px ) {
	
	.Products-info-container {
		flex-direction: column-reverse;
	}
	
}


.Products-container-left {
	display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000001;
  height: 235px;
  align-items: center;
	width: 45%;
}

.Products-container-right {
	display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000001;
  height: 360px;
  align-items: center;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/08/blue-background-circles.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
	width: 45%;
}

.Products-sheet-compimg {
	width: 380px;
}

@media screen and ( max-width: 600px ) and ( min-width: 350px ){
	
	.Products-sheet-compimg {
	   width: 280px;
    }
	
}

.Home-products-lefttext {
	text-align: left;
	color: #000001;
}

.Home-products-lefttext h3 {
	font-size: var(--media-m-font-size);
    line-height: 1.2;
    margin-bottom: 5px;
    font-weight: 500;
    max-width: 492px;
    width: 81%;
}

.Home-products-lefttext p {
	font-size: var(--media-s-font-size);
    font-weight: 300;
    max-width: 534px;
/*    width: 85%;*/
    line-height: 1.3;
    margin-bottom: 30px;
}

@media screen and ( max-width: 1200px ) and ( min-width: 350px ) {
	.Home-products-lefttext h3 {
		width: 100%;
		text-align: center;
		margin: 0 auto 5px auto;
	}
	.Home-products-lefttext p {
		width: 100%;
		text-align: center;
	}
	.Home-products-buttonmain {
		display: flex;
		justify-content: center;
	}
	
	
}




@media screen and ( max-width: 944px ) and ( min-width: 490px ) {
	
	.Products-welcome-container {
		height: 350px;
	}
	
}


@media screen and ( max-width: 750px ) and ( min-width: 350px ) {

	.Products-welcome-container {
		background-position: center top;
	}
	
}


@media screen and ( max-width: 600px ) and ( min-width: 350px ) {

.Products-bigpicture-itemcontainer {
  		width: 96%;
  		display: flex;
  		justify-content: space-between;
  		margin: 20px auto;
		flex-direction: column;
		align-items: center;
		margin-top: 0;
	}
	 .Products-bigpicture-items {
    width: 85% !important;
    height: 52px;
    margin: 7px 0;
    flex-direction: row;
    padding: 19px;
  }
	
	.Products-bigpicture-intro {
		display: none;
	}
	.Products-bigpicture-intro-hidden {
		display: flex;
        padding-top: 10px;
		padding-bottom: 0 !important;
	}
	
	.Products-main-left {
  		width: 90%;
/*		justify-content: flex-end;*/
/*		height: 360px;*/
		padding-left: 10px;
	}
	
	.Products-main-left h1 {
  		font-size: 23px;
		margin-left: 22px;
	}
	
	.Products-main-right {
		width: 0;
		height: 345px;
	}
	
	.Products-main-left p {
  		margin-left: 23px;
  		font-size: var(--media-s-font-size);
		width: 90%;
	}
	
/*
	.Products-welcome-container {
        background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/04/comp-wsystem-Fmobile.png");
		height: 385px;
	}
*/
	
	.learn-hidden-h3 {
		font-size: var(--media-m-font-size);
		margin-bottom: 10px;
	}

	.Products-bigpicture-text h4 {
  		font-size: var(--media-xs-font-size);
/*		margin-left: 15px;*/
		margin-bottom: 0;
	}
	
	.Products-bigpicture-text p {
  		font-size: var(--media-xs-font-size);
	}


	.Products-benefits-mainh2 {
  		font-size: var(--media-m-font-size); 
	}
	
	 .Products-benefits-mainh2 {
    	font-size: 28px;
    	width: 75%;
    	text-align: center;
	}
	
	  .Products-benefits-mainp {
    	width: 81%;
    	font-size: var(--media-xs-font-size);
    	margin-top: 7px;
	}

	.Products-benefits-mainh2 {
  		color: rgb(0, 0, 1);
  		margin: 0 auto;
  		font-size: var(--media-l-font-size);
  		margin-top: 38px;
		line-height: 1;
	}
	
	.Products-benefits-maincontainer {
  		display: flex;
  		flex-direction: column;
  		width: 100%;
		
	}
	
	.Products-benefits-right {
		width: 95%;
	}
	
	.Products-benefits-topComputer {
		width: 90%;
		height: 180px;
	}
	
	.Products-benefits-items h4 {
    	font-size: var(--media-s-font-size);
		width: 80%;
		line-height: 1.1;
	}
	
	.Products-benefits-img {
		display: none;
	}
	
	.Products-benefits-items p {
    	font-size: var(--media-s-font-size);
	}
	
	.Products-benefits-items {
    	width: 85%;
		height: 138px;
	}
	
	.Products-HK-container {
		flex-direction: column !important;
		height: 340px;
	}
	
	.Products-HK-textcontainer {
  		width: 85%;
	}
	
	.Products-HK-textcontainer h5 {
  		font-size: var(--media-s-font-size);
		text-align: center;
	}
	
	.Products-HK-textcontainer p {
  		font-size: var(--media-s-font-size);
	}
	
	.Ready-start-container h2 {
  		font-size: var(--media-l-font-size);
	}
	
	.Ready-start-container p {
  		font-size: var(--media-s-font-size);
		margin-top: 7px;
		width: 90%;
	}
	.Home-leftbackground-container {
  		padding: 7px;
  		height: 220px;
	}
	
	.Products-container-left {
    	height: 208px;
	}
	
	.Products-benefits-center {
		width: 90%;
	}
	
	.Products-benefits-container {
		padding: 0;
	}
	
	
	
}