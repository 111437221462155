@charset "utf-8";
/* CSS Document */

.Reseller-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 53px);
	display: flex;
	flex-direction: column;
}

.Reseller-body-main {
	width: 100%;
	display: flex;
	border-bottom: 6px solid #062347;
}

.Reseller-body {
	margin: 30px 0 30px 30px;
    color: #000001;
}

.Reseller-body-subcontainer {
	display: flex;
	flex-direction: column;
    width: 97%;
}
.Reseller-body-left {
	width: 48%;
}
.Reseller-body-right {
	width: 48%;
}
.Reseller-body-subcontainer h5{
	font-weight: 500;
	font-size: 1em;
	margin: 10px 0 -5px 0;
}
.Reseller-body-list {
	margin: 10px 0 10px 13px;
}
.Reseller-body-list li {
}

.Reseller-header-container {
	width: 100%;
  height: 500px;
  background-image: 
	  linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.35)),
	  url("https://www.digitechsystems.com/wp-content/uploads/2024/07/reseller-section-wr.webp");
  background-size: cover;
  background-repeat: no-repeat;
  color: #000001;
  display: flex;
  align-items: center;
  border-bottom: 6px solid #062347;
  background-position: center center;
}

@media screen and ( max-width: 599px ) and ( min-width: 350px ) {
	
		.Reseller-header-container {
	width: 100%;
  height: 500px;
  background-image: 
	  linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.65)),
	  url("https://www.digitechsystems.com/wp-content/uploads/2024/07/reseller-section-wr.webp");
  background-size: cover;
  background-repeat: no-repeat;
  color: #000001;
  display: flex;
  align-items: center;
  border-bottom: 6px solid #062347;
  background-position: center center;
}
	
	.HowMightSystmHelpYou-Headerbanner-textcontainer {
		padding-left: 30px;
	}
	
	.HowMightSystmHelpYou-Headerbanner-textcontainer p {
		width: 100%;
	}
	
	
    }

.Reseller-header-container h1 {
		font-size: 1.75em;
		line-height: 1;
	font-weight: 500;
}
.Reseller-header-container h5 {
  margin: 0 50px;
  font-size: 1em;
  font-weight: 500;
}

.Reseller-hr {
	width: 500px;
	height: 5px;
	background: #F16E12;
	border: none;
	margin: 15px 0;
}

.Reseller-icons-benefits {
	width: 65%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	background: #BED8F9;
	padding: 33px 0;
}

.Reseller-icons-items {
	margin: 5px 5px;
	padding: 20px 10px;
	display: flex;
    flex-direction: column;
    justify-content: center;
	width: 80%;
	color: #062347;
	background: #D4E3F5;
    border-radius: 5px;
	max-width: 268px;
/*    max-width: 175px;*/
}
.Reseller-icons-items p {
    font-weight: 400;
    font-size: .85em;
	width: 82%;
}

.Reseller-icons-items h5 {
	font-size: 1em;
/*	margin-top: 15px;*/
}
.Reseller-icons-items img {
/*
	margin-left: 15px;
	margin: 0 auto;
*/
}
.Reseller-trust-container {
	border: 2px solid #062347;
	width: 96%;
	display: flex;
	flex-direction: row;
	margin: 15px 0px 0px 0px;
}
.Reseller-trust-container h3 {
		font-size: 1em;
		margin-left: 10px;
}

.Reseller-trust-left {
	background-color: #062347;
	color: #ffffff;
	padding: 20px 15px;
	width: 350px;
	display: flex;
  justify-content: center;
  align-items: center;
}

.Reseller-trust-right {
  padding: 20px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #062347;
}
.Reseller-trust-right p {
	font-size: 1em;
	line-height: 1.3;	
}

.Reseller-iframe-container {
  height: 440px;
  width: 375px;
}

.Reseller-form-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	background: #1263C7;
	color: #000001;
	align-items: center;
	border-bottom: 6px solid #062347;
}
.Reseller-form-container h2 {
	font-size: 1.5rem;
	line-height: 1.1;
	margin-bottom: 10px;
	font-weight: 500;
}
.Sf-form-h4 {
	margin-top: 15px;
  text-align: left;
  width: 86%;
  margin-left: 25px;
  font-size: 13px;
}
.Reseller-form-title {
	width: 45%;
}
.Reseller-form-title h5 {
	font-size: 1.3em;
	margin-top: 10%;
}
.Reseller-form-title ul {
	margin-left: 20px;
}	
.Reseller-form {
	width: 50%;
	color: #ffffff;
	margin: 20px auto;
}
.Reseller-form-items {
	width: 50%;
	height: 535px;
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
	background: #ffffff;
}
.Reseller-form-items input {
		height: 30px;
		border: none;
        border-radius: 3px;
        margin: 3px 0 6px 0;
		padding-left: 5px;
}
.Reseller-form form {
	margin: 0 auto;
}
.Reseller-form-items label {
		font-size: .8em;
	}
.Reseller-form-title {
	width: 50%;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/reseller-footer-wr.webp");
    background-size: cover;
    background-repeat: no-repeat;
}
.Reseller-form-title h5 {
	font-size: 1.3em;
	margin-top: 10%;
}
.Reseller-form-title ul {
	margin-left: 20px;
}	
.reseller-form-button {
	background-color: #F16E12;
	color: #ffffff;
	margin-top: 20px !important;
	width: 240px !important;
	height: 40px !important;
}
.HowMightSystmHelpYou-Headerbanner-textcontainer {
/*	border-left: 3px solid #ffffff;*/
/*    padding: 30px;*/
/*	height: 80px;*/
}
.HowMightSystmHelpYou-Headerbanner-textcontainer h1 {
  	font-size: 2.2em;
  	font-weight: 500;
  	line-height: 1;
}


.Reseller-iframe-container {
	width: 100%;
    height: 550px;
  	font-family: 'Roboto', sans-serif;

}

.Sf-form-container {
/*	width: 376px;s*/
}
.Reseller-icons-items {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.Reseller-icons-textbox {
	display: flex;
	flex-direction: column;
	width: 205px;
}

.reseller-icon-a {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/reseller-revenue-blue.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-position: center;
	margin-right: 15px;
}

.reseller-container-a:hover .reseller-icon-a {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/reseller-revenue-active.png");
}

.reseller-icon-b {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/clock-blue.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-position: center;
	margin-right: 15px;
}

.reseller-container-b:hover .reseller-icon-b {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/clock-active.png");
}

.reseller-icon-c {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/blue-target.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-position: center;
	margin-right: 15px;
}

.reseller-container-c:hover .reseller-icon-c {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/target-active.png");
}

.Reseller-iframe-container {
	width: 375px;
    height: 432px;
    font-family: 'Roboto', sans-serif;
	border: none !important;
	margin: 0 auto;
}
.Ebook-form-container {
	
}

@media screen and (max-width: 1261px) and (min-width: 1135px) {
	
	.Reseller-trust-container h3 {
  		font-size: .9em;
  		margin-left: 7px;
	}
	.Reseller-trust-right p {
  		font-size: .9em;
  		line-height: 1.4;
	}
	
	
}

@media screen and (max-width: 1135px) and (min-width: 650px) {
	
	.Reseller-form-container {
		flex-direction: column;
	}
	
	.Reseller-form-title {
		width: 100%;
	}
	
	.Reseller-form-items {
		width: 100%;
	}
	
	
}


@media screen and (max-width: 856px) and (min-width: 350px) {
	
	.Reseller-body {
		width: 91%;
		margin-bottom: 20px;
	}
	.Reseller-body-subcontainer p {
		font-size: 14px;
	}
	.Reseller-body-main {
		flex-direction: column;
	}
	.Reseller-trust-container {
		display: none;
	}
	.Reseller-header-container {
  		height: 369px;
	}
	.Reseller-icons-benefits {
  		width: 100%;
	}
	.Reseller-icons-items {
  margin: 5px;
  padding: 15px 10px;
  display: flex;
/*  flex-direction: column;*/
  justify-content: space-around;
  width: 75%;
	}
	.Reseller-form-container {
		flex-direction: column;
		justify-content: flex-start !important;
	}
	.Reseller-iframe-container form {

	}
	.Reseller-form-title {
		width: 100%;
		height: 350px;
	}
	.Reseller-form-titlebox {
		padding: 10px;
	}
	.Reseller-form-container h4 {
		font-size: 14px;
	}
	.Reseller-form-items {
  		width: 100%;
/*  		height: 350px;*/
		
	}
	.Reseller-form-container h2 {
  		font-size: 30px;
	}
	.Reseller-body-list li {
		font-size: 12px;
	}
	.Reseller-body-list {
  		margin: 10px 0 20px 0px;
	}
	.Reseller-body-subcontainer h5 {
 		margin: 12px 0 -5px 0;
	}
	
	form.form {
  		width: 90%;
	}
/*
	form.form input.text {
		width: 325px;
		height: 30px;
		border: 1px solid yellow;
	}
*/
	form.form p.submit input {
		width: 325px;
		height: 32px;
	}
	.Reseller-iframe-container {

		width: 99%;
	}
	
	.form-wrapper {
/*		height: 475px;*/
/*		align-items: flex-start;*/
	}
	.Sf-form-container {
/*		border: 1px solid yellow;*/
/*		width: 100%;*/
	}
	.Sf-form-container iframe {
/*		border: 1px solid black;*/
	}
	
	.HowMightSystmHelpYou-Headerbanner-textcontainer h1 {
		font-size: 22px;
	}
	.HowMightSystmHelpYou-Headerbanner-textcontainer p {
		font-size: 12px;
	}
	.Sf-form-h4 {
		margin-left: 30px;
/*		margin-bottom: -15px;*/
	}
	
	
}

.Test-iframe-container {
	border: none;
	height: 615px;
	width: 320px;
}


