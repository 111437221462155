@charset "utf-8";
/* CSS Document */
/*text blue color: #1263C7;*/
/*orange: #F16E12;*/
/*light blue circle: #AECAEC*/

.padding-block {
  width: 100%;
  height: 250px;
  background: red;
}

.nav {
	color: #ffffff;
	text-decoration: none;
}

.Navbar-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #1263C7;
	height: 56px;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.46));
	position: fixed;
    z-index: 10;
}

.Navbar-textlink-container {
  width: 92%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #ffffff;
	visibility: visible;
}

.Navbar-textlink-subcontainer {
	width: 839px;
	display: flex;
    justify-content: space-between;
	font-weight: 400;
	flex-direction: row;
	margin-right: 14px;
	align-items: center;
	margin-top: 2px;
}



.nav-homeIcon-img {
	width: 149px;
	height: 41px;
	margin-left: 14px;
	margin-top: 8px;
}

.Navbar-text-subcontainer a {
	color: #ffffff;
	text-decoration: none;
	width: 218px;
	font-weight: 400;
    text-align: center;
	
	display: flex;
    justify-content: center;
	letter-spacing: 0.025em;
	height: 52px;
    align-items: center;
    font-size: 1rem !important; 
}
.Navbar-text-subcontainer h5 {
	font-weight: 300;
}
.Navbar-login-subcontainer {
	width: 13%;
	display: flex;
    justify-content: space-between;
/*	font-size: .9em;*/
	margin-left: 110px;
}
.Navbar-promo-subcontainer {
	width: 100%;
	background-color: #e6e6e6;
	display: flex;
    flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	padding: 15px 0;
	color: rgb(0, 0, 1);
  font-weight: 500;
}
.Navbar-promo-underline {
	text-decoration: none;
	margin-left: 10px;
}
.Navbar-promo-h5 {
	color: #ffffff;
	text-decoration: none;
}
.Navbar-promo-h5 h5 {
	font-weight: 500;
	font-size: 1rem;
}
.active {
	margin-top: 3px;
	border-bottom: 3px solid #0f50a1;
}
.text-decoration {
	text-decoration: none;
}
.homenav {
	border: none !important;
	width: 175px;
}
.short-nav {
/*	width: 64px !important;*/
}

.learn-nav {
	width: 161px !important;
}

.sign-in-nav {
	padding: 12px 16px 6px 16px;
	border-radius: 35px;
	text-decoration: none;
	color: rgb(0, 0, 1);
	font-size: 1rem;
}

.Navbar-2025promo-subcontainer {
	width: 100%;
	height: 37px !important;
	background: #00660a;
	color: #e6e6e6;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 15px 0;
}

.Navbar-2025promo-subcontainer p {
	color: #e6e6e6;
	font-weight: 500;
	text-align: center;
	font-size: 1rem;
}


.Navbar-epson-subcontainer {
	width: 100%;
	height: 37px !important;
	background: #003F98;
	color: #e6e6e6;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 15px 0;
}

.Navbar-epson-subcontainer p {
	color: #e6e6e6;
	font-weight: 500;
	text-align: center;
	font-size: 1rem;
}


.title-nav {
  text-align: center;
  padding: 5px;
	font-size: 15px;
}


.dropdown-content {
  display: none;
  background: #1263C7;
  min-width: 160px;
	height: 100px;
  position: absolute;
  z-index: 1;
	opacity: 0.95;
	border: none;
	padding: 10px 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);

}

.dropdown-content-link {
	text-decoration: none !important;
    font-size: 1rem !important; 
	color: #ffffff;
	
	width: 90px;
	height: 65px;
}

.dropdown-content-link:hover {
	
	
}

.learn-nav:hover {
	
}

.learn-nav:hover .dropdown-content {
/*  display: block !important;*/
}

.dropdown-content:hover {
/*  display: block !important;*/
}

@media screen and ( max-width: 1200px ) and (min-width: 250px) {
	
	.bm-burger-button {
	  visibility: visible;
    }
	
    .Navbar-textlink-subcontainer {
	  display: none;
	}
	
}

@media screen and (max-width: 850px) and (min-width: 250px) {
	
	.Navbar-promo-h5 h5 {
  		font-size: 12px;
	}
	.nav-homeIcon-img {
    }
	
	.Navbar-text {
  		margin-top: 5px;
   }
	
}
