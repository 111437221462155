@charset "utf-8";
/* CSS Document */

.Accessibility-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 53px);
	display: flex;
	flex-direction: column;
}

.Accessibility-welcome-container {
	margin-top: 50px;
	width: 85%;
	color: #000001;
	margin-bottom: 50px;
	margin-left: 75px;
}

.Accessibility-welcome-emailLink {
	color: #000001;
	text-decoration: none;
	font-weight: bold;
}

.Accessibility-welcome-container p {
	font-size: 16px;
    font-weight: 300;
    color: #000001;
	margin-top: 5px;
}

.Accessibility-welcome-container h1 {
	font-size: 26px;
    font-weight: 900;
    color: #000001;
}

.Accessibility-welcome-container h3 {
	font-size: 24px;
    font-weight: 700;
    color: #000001;
	margin-top: 30px;
}

.Accessibility-top-para {
	margin-top: -10px;
}


.Accessibility-headerbanner-container {
    padding: 30px;
	color: #ffffff;
	width: 100%;
    background-image: 
		linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.35)),
		url("https://www.digitechsystems.com/wp-content/uploads/2024/08/accessibility-banner-wr.webp");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    border-bottom: 6px solid #062347;
    background-position: center center;
}

@media screen and ( max-width: 599px ) and ( min-width: 350px ) {
	
		.Accessibility-headerbanner-container {
    padding: 30px;
	color: #ffffff;
	width: 100%;
    background-image: 
		linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.65)),
		url("https://www.digitechsystems.com/wp-content/uploads/2024/08/accessibility-banner-wr.webp");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    border-bottom: 6px solid #062347;
    background-position: center center;
  }
	
}



.Accessibility-headerbanner-textcontainer {
	height: 365px;
  padding: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Accessibility-headerbanner-textcontainer h1 {
	font-size: 1.85em;
  font-weight: 500;
  line-height: 1.15em;
  margin-left: 80px;
	color: #000001;
}

.Accessibility-headerbanner-textcontainer p { 
  font-size: 1em;
  font-weight: 300;
  line-height: 1;
  margin-top: 6px;
}

.sup-sizing {
	font-size: .75em;
}

.Accessibility-ul {
	margin-left: 15px;
}

.Accessibility-li {
	font-size: 16px;
    font-weight: 300;
    color: #000001;
    margin-top: 4px;
}

@media screen and (max-width: 850px) and (min-width: 350px) {
	
	.Accessibility-welcome-container {
	    margin-left: 25px;
    }

	
	.Accessibility-Headerbanner-container {
		height: 280px;
		padding: 0;
	}
	.Accessibility-headerbanner-textcontainer h1 {
		font-size: 20px;
		padding: 20px;
		margin-left: 0px;
	}
	
	.Accessibility-welcome-container p {
		font-size: 12px;
	}
	.Accessibility-welcome-container h1 {
  		font-size: 23px;
	}
	.Accessibility-welcome-container h3 {
  		font-size: 23px;
		font-weight: 900;
		width: 75%;
	}
	.Accessibility-headerbanner-textcontainer {
        padding: 10px;
	}
	
	
}
