.Pillar-body-container {
   background: #70a1dd;
	padding: 45px 0;
}

.Pillar-main-container {
	width: 95%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0 auto;
}

.Pillar-table-subcontainer {
	width: 18%;
	height: 350px;
	background: #ffffff;
}

.Pillar-table-sidemenu {
	display: flex;
	flex-direction: column;
}

.Pillar-main-subcontainer {
	width: 78%;
	background: #ffffff;
	padding: 0px 15px;
}

.Pillar-about-subcontainer {
	border: 1px solid red;
}

.Pillar-table-list {
	list-style-type: none; 
}

.Pillar-item-container {
	width: 90%;
	margin: 7px auto;
}

.Pillar-item-container h1 {
/*	color: darkgrey;*/
	font-size: 42px;
}

.Pillar-matters-subcontainer h1 {
/*	color: darkgrey;*/
	font-size: 42px;
}

.Pillar-matters-subcontainer {
	border: 1px dotted red;
}

.Pillar-item-container h3 {
	font-size: 28px;
}

.Pillar-item-container p {
	font-size: 16px;
}

.Pillar-list {
	margin-left: 15px;
}