@charset "utf-8";
/* CSS Document */

/*
.bm-burger-bars-hover {
  background: #a90000;
}

.bm-burger-bars-hover {
  background: #a90000;
}
*/

.bm-menu {
  background: #373a47;
  padding: 1.5em 1.5em 0;
  font-size: 1.15em;
	height: 272px;
}

.bm-burger-button {
	width: 40px;
}
.bm-cross{
	width: 3px !important;
  height: 25px !important;
	background: #ffffff;
}


/* Hide the toggle checkbox */
#expand-toggle {
  display: none;
}

/* Hide expandable content by default */
.expandable {
  visibility: collapse;
  background: #ddd;
}

/* Style the button */
#expand-btn {
  display: inline-block;
  margin-top: 12px;
  padding: 5px 11px;
  background-color: #ff7;
  border: 1px solid;
  border-radius: 3px;
}

/* Show hidden content when the checkbox is checked */
#expand-toggle:checked ~ * .expandable {
  visibility: visible;
}

/* Style the button when the checkbox is checked */
#expand-toggle:checked ~ #expand-btn {
  background-color: #ccc;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  height: 13px !important;
  right: 0px;
  top: 22px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: #94C0F5;
    border-radius: 2px;
    left: 318px;
   position: absolute;
    height: 2px !important;
    right: 5px;
    top: 21px;
    opacity: 1;
    width: 20px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #062347;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 34px !important;
  width: 34px !important;
  right: 35px !important;
  top: 13px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  color: #ffffff;
  top: 0;
  height: 389px !important;
  width: 270px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #ededf4 !important;
  padding: 0 !important;
    font-size: 1.15em;
	height: 322px !important;
	border-radius: 5px;
	margin-right: 22px;
	margin-top: 56px;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.33));
	overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
/*	margin-top: 6px;*/
	height: 62%;
	color: rgb(0, 0, 1);
}

/* Individual item */
.bm-item {
  border-bottom: 1px solid #cccccc;
	
	font-size: 16px;
  font-weight: 500 !important;
  color: --sys-on-surface;
  height: 53px !important;
  line-height: 0 !important;
  padding: 0px 20px !important;
	
}

.bm-item:hover {
	background: #d5d5db !important;
}

/* Styling of overlay */
.bm-overlay {
	width: 100% !important;
	left: 0 !important;
    top: 0 !important;
	background: none !important;
}

.bm-burger-button {
	visibility: hidden;
}

.Sidebar-link {
	text-decoration: none;
	color: rgb(0, 0, 1);
	padding-left: 14px;
	display: flex !important;
	align-items: center !important;
}

.bm-border-none {
	border: none;
}

.bm-cross-button {
	display: none;
}


@media screen and (max-width: 1200px){
	
	.bm-burger-button {
	  visibility: visible;
    }
	
	
	
}
