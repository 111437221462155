@charset "utf-8";
/* CSS Document */

/*text blue color: #1263C7;*/
/*orange: #F16E12;*/
/*light blue circle: #AECAEC*/


* {
/*  border: 1px solid red;*/
}


.Home-hold {
	height: 700px;
	width: 100%;
}

.Home-container {
	width: 100%;
	height: 600px;
	
}

.Home-hold-container {
	padding-top: 100px;
	padding-left: 30px;
}

.Home-hold-container a {
	text-decoration: none;
}

.navbar-text-link {
	text-decoration: none;
}
.welcome-banner-container {
  display: flex;
  width: 100%;
  background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/main-systm-wr.webp");
  background-size: cover;
  background-position: center;
  border-bottom: 6px solid #062347;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  height: 500px;
}
.welcome-banner-left {
  width: 50%;
 }
.welcome-banner-right {
  width: 50%;
}
.welcome-img {
  float: right;
  margin: 50px 100px;
}
.welcome-left-container {
  	float: right;
  	color: #ffffff;
  	display: flex;
  	justify-content: center;
  	height: 65%;
  	margin-left: 25px;
  	margin-top: 5%;
	padding-left: 4%;
	flex-direction: column;
}
.welcome-left-textcontainer {
	width: 55%;
}
.welcome-left-textcontainer h1 {
    font-size: 1.85em;
    line-height: 1.1;
	width: 100%;
	max-width: 500px;
	font-weight: 500;
  }
.welcome-left-textcontainer p {
    margin-top: 5px;
    font-weight: 300;
	font-size: 1.15em;
    width: 100%;
	max-width: 600px;
	margin-bottom: 35px;
}
.welcome-button-container {
	width: 263px;
    display: flex;
    justify-content: space-between;
}
.welcome-button {
    font-size: 16px;
    border-radius: 20px;
    background: #ffffff;
    color: #3C6090;
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding: 9px 23px;
    letter-spacing: 0.02em;
	text-decoration: none;
	border: .7px solid #141414;
}
.welcome-button:hover {
	background: #e2e9f2;
}
.learn-button {
  	color: #ffffff;
	background: #1263C7;
	border: none;
}
.learn-button:hover {
    background: #e2e9f2;
}
.welcome-bigpicture-maincontainer {
	display: flex;
	flex-direction: row;
	width: 100%;
    margin: 0 auto;
	background: #D4E3F5;
    border-bottom: 6px solid #062347;
}
.home-bigpicture-mobile {
	display: none;
}
.welcome-bigpicture-container {
	width: 95%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	margin-top: 40px;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 40px;
}
.welcome-bigpicture-items {
	width: 20%;
	height: 151px;
	background-color: #BED8F9;
	padding: 20px;
	display: flex;
	flex-direction: column;
	border-bottom: 10px solid #1263C7;
    border-radius: 4px;
    border: 2px solid #062347;
}

.wel-img {
	width: 65px;
	height: 65px;
	margin: 0 auto;
}

.welcome-bigpicture-items:hover {
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
}

.img-active-cloud {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/data-security-bluecloud.png");
  background-size: contain;
/*  height: 500px;*/
  background-repeat: no-repeat;
  background-position: center center;
}

.welcome-bigpicture-a:hover .img-active-cloud {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/white-bg-cloud.png");
}

.img-active-focus {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/blue-focused.png");
  background-size: contain;
/*
  height: 500px;
	width: 75px;
*/
  background-repeat: no-repeat;
  background-position: center center;
}

.welcome-bigpicture-b:hover .img-active-focus {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/focused.png");
}

.img-active-money {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/blue-tech-money.png");
  background-size: contain;
/*  height: 500px;*/
  background-repeat: no-repeat;
  background-position: center center;
}

.welcome-bigpicture-c:hover .img-active-money {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/active-tech-money.png");
}

.img-active-fast {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/blue-fast-processing.png");
  background-size: contain;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
}

.welcome-bigpicture-d:hover .img-active-fast {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/whitebg-fast-processing.png");
}

.welcome-bigpicture-items h2 {
    color: rgb(0, 0, 1);
	font-size: 18px; 
	font-weight: 500;
	margin-bottom: 5px;
	line-height: 1.1;
	margin-top: 4px;
}
.welcome-bigpicture-items p {
	font-size: 14px; 
	font-weight: 400;
  color: rgb(0, 0, 1);
  line-height: 1.2;
}
.welcome-bigpicture-img {
	
}
.Home-bigpicture-round {
	    width: 150px; 
        height: 150px; 
        border-radius: 80px;
	border: 3px solid #1263C7;
}
.kidrobot {
	margin-bottom: 5px;
}


.Home-big-picture{
	width: 100%;
	background: #D4E3F5;
	border-bottom: 12px solid #1263C7;
	padding: 20px 0;
}
.Home-bigpicture-itemcontainer {
/*	width: 93%;*/
	display: flex;
	margin: 0 auto;
	justify-content: space-around;
}
.Home-bigpicture-items {
	width: 38%;
	height: 180px;
    color: #1263C7;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 0;
}
.Home-bigpicture-container {
	display: flex;
	flex-direction: row;
}
.Home-bigpicture-toptitle {
	color: #000;
	margin: 15px 0 0 35px;
}
.Home-bigpicture-items h4 {
	font-size: 2em;
    font-weight: 500;
    line-height: 1.2;
	margin-left: 12px;
}
.Home-bigpicture-items p {
	font-size: 1.25em;
    font-weight: 300;
    width: 86%;
	margin-left: 12px;
}
.Home-bigpicture-items hr {
	width: 100%;
	border: 2px solid #1263C7;
	margin: 10px 0;
}

.benefits-section {
  width: 100%;
  display: flex;
  flex-direction: column;
	border-bottom: 6px solid #062347;
}
.benefits-section h2 {
    width: 52%;
/*	min-width: 527px;*/
    color: rgb(0, 0, 1);
    font-size: 2em;
    font-weight: 500;
    text-align: center;
    margin: 50px auto 0 auto;
	line-height: 1;
  }
 .benefits-section p {
    font-weight: 300;
    color: rgb(0, 0, 1);
    margin-top: 5px;
	font-size: 2.17em;
    margin-bottom: 25px;
	line-height: 1.1;
 }
 .benefits-subtitle {
    font-weight: 300;
	text-align: center;
    width: 58%;
	 margin: 0 auto;
	 font-size: 1.15em !important;
	 color: rgb(0, 0, 1);
	 margin-top: 10px;
	 max-width: 980px;
 }
.benefits-section-container {
	width: 100%;
}

.benefits-main-container {
    width: 100%;
}
.benefits-section-a {
	display: flex;
	flex-direction: row;
	padding: 0;
	align-items: center;
	height: 445px;
	margin-bottom: 28px;
}
.benefits-section-b {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 436px;
}
.benefits-section-c {
	display: flex;
	padding: 0;
	align-items: center;
	height: 277px;
}
.benefits-item-container {
    width: 50%;
}
.benefits-sectionA-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-circs-oncircs.png");
  background-size: contain;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
}
.benefits-sectionA-left h3 {
    font-size: 1.75em;
    width: 55%;
    margin-left: 100px;
 }
.benefits-sectionA-left p {
    margin-left: 100px;
	font-size: 18px;
}
.get-started-button {
	color: rgb(0, 0, 1);
	font-size: 18px;
	font-weight: 500;
}
.get-started-button span {
	font-weight: 500;
}
.arrow {
    color: rgb(0, 0, 1);
    margin: 1em 0;

    &::after {
        display: inline-block;
        padding-left: 8px;
        content: "\0279E";
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }

    &:hover {
        color: rgb(0, 0, 1);
/*        background-color: #f6f3ed;*/

        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}
.benefits-sectionA-right {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
	height: 300px;
}
.benefits-sectionA-right h3 {
    font-size: 1.25em;
    font-weight: 500;
	color: rgb(0, 0, 1);
	width: 80%;
	line-height: 1.1;
 }
.benefits-sectionA-image {
  margin-left: 15%;
}
.benefits-sectionB-left {
	width: 50%;
  	display: flex;
  	justify-content: center;
  	align-items: center;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-circs-oncircs.png");
  background-size: contain;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
}
.benefits-sectionB-right {
	width: 50%;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	color: #1263C7;
  	justify-content: center;
	height: 350px;
}
.benefits-sectionB-right h3 {
    font-size: 1.25em;
    font-weight: 500;
	line-height: 1.1;
	color: rgb(0, 0, 1);
	width: 80%;
  }
.benefits-sectionB-right p {
    width: 95%;
	font-size: 18px;
 }
.benefits-section-image {
/*	max-width: 360px;*/
}
.sectionB-img {
	width: 390px;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
}
.sectionC-img {
	height: 365px;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
}
.sectionA-img {
	height: 365px;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
}
.benefits-sectionC-left {
  width: 50%;
  color: #1263C7;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
	height: 300px;
}
.benefits-sectionC-left h3 {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.1;
	color: rgb(0, 0, 1);
  }
.benefits-sectionC-left p {
	font-size: 15px;
 }
.benefit-textbutton-sectionC {
   margin-left: 100px;
}
.benefits-sectionC-right {
  background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-circs-oncircs.png");
	background-size: contain;
	height: 500px;
  	background-repeat: no-repeat;
	background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.benefits-sectionC-image {
  margin-left: 110px;
  margin-top: 69px;
}
.benefit-textbutton-container {
  width: 75%;
}
.benefit-textbutton-container p {
  	font-size: 15px;
  	line-height: 1.1;
	color: rgb(0, 0, 1);
	font-weight: 300;
/*	width: 84%;*/
}
.benefit-textbutton-container a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 700;
}
.keyfit-section {
    color: #1263C7;
    margin-top: 10px;
  width: 100%;
  display: none;
}
.keyfit-section h2 {
    text-align: center;
    font-size: 34px;
    color: #1263C7;
}
.keybenefit-section-container {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 18px auto;
}
.benefit-workflow-icon {
  width: 90px;
  height: 75px;
  background: #1263C7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.keybenefit-section-text {
  margin-left: 10px;
  line-height: 1;
}
.keybenefit-section-text h5 {
    font-size: 1.65em;
  }
 .keybenefit-section-text p {
    font-size: 18px;
 }
.keybenefit-section-item {
  border: 1.5px solid #1263C7;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 7px 0 0 0;
  width: 500px;
}

.get-started-banner {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1263C7;
  padding: 50px 0;
/*  background-color: #D4E3F5;*/
}
.get-started-banner h2 {
    font-weight: 500;
    font-size: 2.4em;
	color: rgb(0, 0, 1);
	width: 32%;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 5px;
  }
.get-started-banner p {
    font-weight: 300;
    font-size: 1.15em;
    margin-top: 5px;
    width: 60%;
    text-align: center;
	line-height: 1.1;
	color: rgb(0, 0, 1);
}
.get-started {
/*    color: #ffffff;*/
/*	border: 3px solid #062347;*/
	margin: 0;
    width: 187px !important;
/*	background: #062347;*/
	margin-top: 15px;
}
.promo-section-textblock {
	display: flex;
	flex-direction: column;
	width: 90%;
	margin-left: 65px;
}

.home-gains-container {
	width: 100%;
	padding: 50px 0;
	border-bottom: 6px solid #062347;
	background: #BED8F9;
	display: flex;
    justify-content: center;
}
.home-gains-itemscontainer {
	display: flex;
	flex-direction: row;
	width: 90%;
	margin: 0 auto;
	justify-content: space-between;
    align-items: center;
}
.home-gains-imgcontainer {
	width: 100px;
	height: 100px;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/uptime-emblem.png");
	background-size: contain;
  	background-repeat: no-repeat;
	background-position: center center;
}
.home-gains-textcontainer h2 {
	color: rgb(0, 0, 1);
	font-size: 1.25em;
	font-weight: 500;
}
.home-gains-textcontainer p {
	color: rgb(0, 0, 1);
	font-size: 15px;
    line-height: 1.2;
    font-weight: 300;
	width: 50%;
}
.home-gains-textcontainer {
	color: #ffffff;
}
.home-gains-itemsSubcontainer {
	display: flex;
/*	flex-direction: row;*/
}
.home-gains-itemsSubcontainer a {
	text-decoration: none;
}
.home-gains-items {
	display: flex;
  justify-content: row;
  flex-direction: column;
}
.home-subtitle-welcometext {
	color: #F16E12;
	font-size: 12px;
	margin: 0 0 15px 25px;
}
.home-products-container {
	width: 100%;
	display: flex;
	color: rgb(0, 0, 1);
	border-bottom: 6px solid #062347;
	padding: 20px 0;
}
.home-products-item {
	width: 50%;
}
.products-sheet-compimg {
	width: 71%;
}
.products-container-right {
	display: flex;
	justify-content: center;
    align-items: center;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-circs-oncircs.png");
	background-size: contain;
  	background-repeat: no-repeat;
	background-position: center center;
}
.products-container-left {
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
	color: rgb(0, 0, 1);
	height: 400px;
}
.home-leftbackground-container {
	padding: 15px;
	height: 200px;
	display: flex;
    flex-direction: column;
	justify-content: space-around;
	width: 55%;
	margin-left: 50px;
}
.products-sheet-img {
	width: 325px;
    height: 250px;
}
.products-container-left h3 {
	font-size: 1.5em;
  	line-height: 1;
  	margin-bottom: 5px;
	font-weight: 500;
	max-width: 492px;
}
.products-container-left p {
	font-size: 16px;
    font-weight: 300;
	max-width: 534px;
}
.Home-products-lefttext {
	margin-bottom: 20px;
	width: 88%;
}
.Home-products-buttonbox {
	display: flex;
	flex-direction: row;
	width: 345px;
	justify-content: space-between;
}
.Home-products-buttoncontainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-right: 15px;
}
.products-container-left {
	height: 390px;
}
.Home-products-buttoncontainer a {
	margin: 0;
	display: flex;
  justify-content: center;
	text-decoration: none;
}
.products-container-left h4 {
	font-size: 1.4em;
  	font-weight: 500;
/*  	margin: 50px auto 15px auto;*/
	margin-top: 50px;
	margin-bottom: 3px;
}
.Home-products-buttonmain p {
	font-weight: 300;
  	font-size: 1.1em;
  	line-height: 1.1;
 	margin-bottom: 25px;
  	width: 80%;
}
.Home-products-buttoncontainer h5 {
	font-size: 1.1em;
	font-weight: 500;
}
.Home-products-button {
	border: 3px solid #1263C7;
	background-color: #1263C7;
	color: #ffffff;
	margin: 10px 0 0 0;
	font-size: 12px;
	font-weight: 500;
	padding: 12px 50px;
	border-radius: 30px;
	width: 182px;
}
.Home-products-img {
	height: 75%;
}
.Home-products-button:hover {
	border: 3px solid #F16E12;
	background-color: #F16E12;
	color: #ffffff;
}
.Home-contact-container {
	width: 100%;
	display: flex;
	justify-content: space-around;
	background: #EFF4FB;
	border-bottom: 6px solid #062347;
}
.contact-container-left {
	width: 50%;
	display: flex;
    flex-direction: column;
    justify-content: center;
   background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/contact-bottom-wr.webp");
	background-size: cover;
  	background-repeat: no-repeat;
	background-position: center center;
	height: 450px;
}
.contact-container-right {
	width: 50%;
	display: flex;
    flex-direction: column;
    justify-content: center;
	padding-left: 100px;
	background: #BED8F9;
	height: 450px;
	align-items: center;

}
.contact-left-textblock {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: 85%;
}
.contact-left-textblock h2 {
	font-size: 2em;
}
.contact-left-textblock p {
	font-size: 1.15em;
}
.Home-contact-items {
	color: rgb(0, 0, 1);
}
.Home-contact-items h2 {
	font-size: 1.25em;
	font-weight: 700;
}
.Home-contact-items p {
	font-size: 1rem;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 5px;
	margin-top: 6px;
}

.Home-contact-img {
	width: 200px;
}

.Home-contact-iconcontainer {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: 85%;
/*	border: 1px solid red;*/
}

.Home-contact-blockcontainer {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.Home-contact-fast-call {
	width: 43px;
	height: 43px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 6px;
}
.Home-contact-subcontainer {
	display: flex;
/*	margin-top: 7px;*/
	align-items: center;
/*	width: 100%;*/
}
.Home-contact-subcontainer h5 {
	font-size: .85rem;
	font-weight: 400;
}
.product-sheet-container {
	width: 90%;
}
.bpi-bottom {
/*	margin-top: 7px;*/
}
.demo-call {
	margin-bottom: 15px;
	
}


@media screen and ( max-width: 1445px ) and ( min-width: 1376px ) {
	
	
}

@media screen and ( max-width: 1445px ) and ( min-width: 1376px ) {
	
	
	.Home-products-buttonbox {
/*  		width: 80%;*/
    }
	.Home-products-img {
    	height: 55%;
    }
	
	
}

@media screen and ( max-width: 1375px ) and ( min-width: 1271px ) {
	
	.Home-products-button {
	}
	
}

@media screen and ( max-width: 1270px ) and ( min-width: 1165px ) {
	.Home-contact-iconcontainer {
  	justify-content: center;
  	flex-direction: column;
  }
	
	.Home-products-buttonbox {
		
	}
	
}

@media screen and ( max-width: 1165px ) and (min-width: 1035px) {
	
	.welcome-bigpicture-container {
  width: 90%;
	}
	.Home-producs-button {
		font-size: .7em;
        max-width: 180px;
        padding: 12px 20px;
   }
	.Home-products-buttonbox {
/*		flex-direction: column !important;*/
	}
	
}

@media screen and ( max-width: 1034px ) and ( min-width: 986 ) {
	
	.welcome-bigpicture-items p {
  		color: #fff;
  		font-size: .9em;
  		font-weight: 300;
  		line-height: 1.1;
    }
	.products-sheet-img {
		width: 94%;
	}
	   
	
	
	
}

@media screen and ( max-width: 985px ) and ( min-width: 851px ) {
	
	.Products-info-container {
		display: none;
	}
	
	.products-sheet-compimg {
  		width: 45% !important;
	} 
	.Home-products-lefttext {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	
	.video-demo-section {
		flex-direction: column;
		justify-content: center;
        align-items: center;
	}
	.video-demo-image {
		width: 72%;
	}
	.demo-call {
		margin-left: 0;
	}
	.video-demo-left {
		width: 90%;
		height: 262px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;
	}
	.video-demo-left p {
		width: 85%;
		margin: 0;
		text-align: center;
	}
	.video-demo-right {
		width: 90%;
		padding-bottom: 35px;
	}
	.promo-section {
		height: 220px;
	}
	.promo-section-right {
		display: none;
	}
	.promo-section-left {
		text-align: center;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;
	}
	.promo-section-textblock {
		width: 80%;
	}
	.promo-section-textblock {
	width: 90%;
}
	.benefits-main-container {
/*		flex-direction: column !important;	*/
	}
	.benefits-sectionA-right {
		width: 100%;
	}
	.benefits-sectionA-left {
		width: 100%;
		height: 450px;
	}
	.benefits-sectionB-right {
		width: 100% ;
		height: 220px;
        margin-bottom: 35px;
	}
	.benefits-sectionB-left {
		width: 100%;
		height: 450px;
	}
	.benefits-sectionC-right {
		width: 100%;
		height: 450px;
	}
	.benefits-sectionC-left {
		width: 100%;
	}
	.benefits-section-a {
/*		flex-direction: column !important;*/
	}
	.benefits-section-c {
/*		flex-direction: column-reverse !important;*/
	}
	.benefits-section-image {
/* 		 width: 75%;*/
 		 margin: 25px 0 40px 0;
	}
	.benefit-textbutton-container {
      width: 75%;
      height: 266px;
      display: flex;
      flex-direction: column;
      justify-content: center;
	  align-items: center;
	  text-align: center;	
}
	.welcome-banner-right {
  	  width: 82%;
	}
	.promo-section-button {
		margin-left: none;
	}
	.promo-section-textblock {
		margin-left: none;
	}
/*
	.products-container-left h3 {
		font-size: 2em;
		width: 100%;
		margin-top: 25px;
		text-align: center;
	}
*/
	.Home-products-buttonbox {
		width: 325px;
		max-width: none;
		margin-bottom: 12px;
		flex-direction: row !important;
		margin: 0 auto;
	}
	.Home-products-buttoncontainer {
		align-items: flex-start;
		margin-right: 0;
	}
	.welcome-bigpicture-container h2 {
		font-size: 19px;
        width: 68%;
	}
	.welcome-bigpicture-items {
		width: 41%;
		height: 127px;
		padding-top: 12px;
	}
	.welcome-bigpicture-maincontainer {
		width: 95%;
		flex-direction: column;
		justify-content: space-around;
        align-items: center;
        height: 380px;
		display: none;
	}
	.home-bigpicture-mobile {
		display: flex !important;
		flex-direction: column;
		background: #D4E3F5;
		width: 100%;
		padding: 25px 0;
	}
	.home-bigpicture-desktop {
		display: none !important;
	}
	.welcome-bigpicture-top {
	  margin-bottom: 10px;
   } 
	.welcome-bigpicture-bottom {
	  margin-top: 10px;
   } 
	 .welcome-bigpicture-container {
    	width: 94%;
		 margin: 7px auto;
	}
	.welcome-banner-container {
		height: 420px;
	}
	.get-started-button {
 	 font-size: 18px;
 	 font-weight: 500;
  	 border: 3px solid #F16E12;
 	 padding: 12px 50px;
 	 border-radius: 4px;
}
	.benefit-textbutton-sectionC {
  margin-left: 0px;
/*		width: 75%;*/
}
	.Home-products-container {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
}
	.products-container-left {
  		display: flex;
 		flex-direction: column;
  		width: 100%;
		height: 290px;
	}
	.Home-products-item {
      width: 100%;
}
	.Home-leftbackground-container {
		width: 55%;
}
	.products-container-left h4 {
  font-size: 1.4em;
  margin-top: 0px;
}
	.products-container-left p {
  		font-size: 1.2em;
  		width: 75%;
		text-align: center;
		margin-top: 6px;
	}
	.Home-products-buttonmain {
  align-items: center;
	}
	.Home-products-button {
		max-width: none;
		font-size: 14px;
	}
	.Home-contact-container {
/*		flex-direction: column !important; */
	}
	.Home-contact-items {
       color: rgb(0, 0, 1);
       width: 100%;
	  margin: 0 auto;
/*		text-align: center;*/
/*		height: 300px;*/
		display: flex;
    justify-content: center;
/*    align-items: center;*/
}
	.Home-contact-items p {
/*  		margin: 0 auto;*/
}
	.Home-contact-iconcontainer {
		width: 90%;
		justify-content: space-between;
/*		margin-left: 7%;*/
	}
/*
	.contact-left-textblock {
		width: 50%;
	}
*/
	.welcome-left-textcontainer p {
  		margin-top: 3px;
  		font-weight: 300;
  		font-size: 1em;
  		margin-bottom: 25px;
}
	.products-sheet-img {
/*		width: 58%;*/
/*		margin-top: 30px;*/
	}
	.contact-container-left {
		margin: 0 auto;
		height: 450px;
	}
	.Home-gains-itemscontainer {
		flex-direction: column !important;
	}
	.Home-gains-textcontainer h2 {
		text-align: center;
	}
	.Home-gains-textcontainer p {
		text-align: center;
		margin: 0 auto 30px auto;
}
	.Home-gains-container {
/*		margin-top: 10px;*/
	}
	.welcome-left-container {
		margin-top: 80px;
	}
	.Home-contact-items h2 {
      
    }
	.Home-contact-items p {
	  font-size: .9em;
      width: 80%;
    }
	
	
	.products-container-right {
		height: 390px;
	}
	
	.Home-products-button {
		font-size: 12px;
		width: 183px;
	}
	
	
}/*end*/

@media screen and (max-width: 850px) and (min-width: 350px) {
	
	.products-sheet-compimg {
/*  		width: 75% !important;*/
		max-width: 340px !important;
	} 
	
	.welcome-bigpicture-maincontainer {
		display: none;
	}
	 .Home-products-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
	  .Home-products-item {
        width: 100%;
		  height: 255px;
  }
	 .products-container-left h3 {
    font-size: 1.8em;
	}
	.home-bigpicture-mobile {
		display: flex !important;
		flex-direction: column;
		background: #D4E3F5;
		width: 100%;
		padding: 25px 0;
	}
	
/*
	.welcome-button {
		padding: 10px 27px;
    	font-size: 12px;
    }
*/
	
	.Navbar-promo-h5 h5 {
  		font-size: 14px;
  }
	.welcome-bigpicture-container {
		width: 100%;
		flex-direction: column;
		margin: 0;
	}
	.welcome-bigpicture-items {
		width: 85%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 3px 0;
		padding-bottom: 17px;
		height: 75px;
		padding-top: 20px;
	}
	.benefits-section-title {
		width: 90% !important;
		font-size: 26px !important;
	}
	.welcome-bigpicture-items p {
    	font-size: 13px;
	}
	.welcome-bigpicture-textbox {
		margin-left: 15px;
	}
	
	.benefits-subtitle {
		font-size: 14px !important;
    	width: 90% !important;
	}
	.benefit-textbutton-container h3 {
		font-size: 18px;
    	width: 90%;
	}
	.benefit-textbutton-container p {
		font-size: 13px;
	}
	.benefit-textbutton-container a {
  		text-decoration: none;
  		font-size: 16px;
  		font-weight: 500;
	}
	
	.Home-gains-textcontainer h2{
		font-size: 26px;
    	font-weight: 500;
    	width: 90%;
    	margin: 0 auto 5px auto;
		text-align: center;
	}
	.Home-gains-textcontainer p {
		font-size: 13px;
		width: 90%;
		text-align: center;
		margin: 0 auto;
	}
	  .products-container-left h3 {
    	font-size: 22px;
    	text-align: center;
    	width: 50%;
	}
	.welcome-bigpicture-container h2 {
		font-size: 16px;
		
	}
	.products-container-left h3 {
		font-size: 22px;
    	text-align: center;
	}
	  .products-container-left p {
    	font-size: 14px;
    	width: 87%;
		margin: 5px 0;
		text-align: center;
	}
	.Home-contact-subcontainer h5 {
  		font-size: 13px;
  		font-weight: 500;
	}
	.contact-left-textblock {
       margin-left: 18%;
       margin: 0 auto;
    }
	.Products-HK-textcontainer {
  		font-size: 13px !important;
        width: 68% !important;
    }
	.Home-contact-iconcontainer {
		width: 62%;
		align-items: center;
		margin-bottom: 10px;
    }
	.welcome-left-textcontainer {
		width: 95%;
	}
	.welcome-left-textcontainer h1 {
        font-size: 20px !important;
        width: 95% !important; 
		margin-top: 132px !important;
	}
	.welcome-left-textcontainer p {
		font-size: 12.5px;
		margin-bottom: 20px;
		line-height: 1.2;
	}
	.welcome-left-container {
		margin-bottom: 20px;
		margin-left: 0;
		width: 90%;
		padding-left: 25px;
	}

	.benefits-section-a {
		flex-direction: column !important;
		height: 540px;
	}	
	.benefits-section-b {
		flex-direction: column !important;
		height: 500px;

	}
	.benefits-section-c {
		flex-direction: column-reverse !important;
		height: 500px;
	}	
	.sectionC-img {
		width: 85%;
        height: 92%;

	}
	
	.benefits-sectionB-left {
		width: 100%;
		height: 350px;
		background-size: 298px;
		margin-top: 26px;
	}
	
	.benefits-sectionB-right {
		width: 100%;
/*		height: 190px;*/
	}
	.benefits-sectionC-right {
		width: 100%;
		height: 350px;
		background-size: 298px;
	}
	.sectionB-img {
		width: 79%;
	}
	.benefits-sectionC-left {
		width: 100%;
		height: 350px;
	}
	
	
	
	.benefit-textbutton-sectionC {
  		margin-left: 0;
		width: 100%;
	}
	.benefits-sectionA-right {
		width: 100%;
		height: 256px;
	}
	.sectionA-img {
      height: 78%;
	}
	.benefits-sectionA-left {
		width: 100%;
		height: 350px;
		background-size: 298px;
	}
	.Home-gains-itemscontainer {
  		display: flex;
  		flex-direction: column;
	}
	.Home-leftbackground-container {
		width: 91%;	
		height: 168px;
		align-items: center;
	}
	  
	.Home-products-button {
/*		width: 200px !important;*/
	}
	.Home-contact-container {
		flex-direction: column !important;
	}
	.contact-container-left {
		width: 100%;
		height: 290px;
		display: none;
	}
	.contact-container-right {
		width: 99%;
		height: 290px;
		padding-left: 0px;
	}
	.Home-contact-items h2 {
		text-align: center;
		font-size: 2em;
		margin-left: 0%;
	}
	.Home-contact-blockcontainer {
		flex-direction: column;
	}
	.Home-contact-items p {
  		font-size: 14px;
		width: 100%;
		text-align: center;
		margin: 5px auto 0 auto;
	}
	.Home-contact-subcontainer {
  		display: flex;
  		margin-top: 8px;
  		align-items: center;
  		margin-left: 0 !important;
		
	}
	.contact-button {
		margin: 0 auto !important;
        margin-top: 18px !important;
	}
	
	.Home-gains-container {
		margin-top: 0;
	}
	.welcome-button-container {

	}
	.Home-products-lefttext {
		justify-content: center;
       align-items: center;
       display: flex;
       flex-direction: column;
		margin: 0 auto;
	}
	.learn-button {
		margin-left: 0;
/*		margin-top: 15px;*/
	}
	.nav-learn-link {
		display: none;
	}
	.Products-bigpicture-imgblock {
		width: 111px;
		margin-right: 10px;
/*		height: 42px;*/
/*		margin-top: 26px;*/
		margin-bottom: 0;
	}
	.products-container-left {
      height: 300px;
	}
	.Home-products-buttoncontainer {
		margin: 0;
		margin-top: 10px;
	}
	.demo-call {
 	  margin-bottom: 5px;
	}
	
	
	
	
}


@media screen and (max-width: 547px) and (min-width: 350px) {
	
	.Products-HK-textcontainer {
		width: 90% !important;
	}
	.Products-HK-container {
		height: 380px !important;
	}
	.welcome-banner-container {
		background-image: url(https://www.digitechsystems.com/wp-content/uploads/2024/07/systm-resized-mobile-wr.webp);
		background-position: center top;
		height: 385px;
	}
	  .benefits-sectionB-right {
      width: 100% !important;
	}
	.benefits-sectionC-left {
		width: 100% !important;
	}	
	.benefits-sectionA-right {
		width: 100% !important;
	}
	
}


@media screen and (max-width: 404px) and (min-width: 200px) {
	
	.Home-products-buttonbox {
    	width: 100%;
		flex-direction: column;
	}
	
	
	
}




