@charset "utf-8";
/* CSS Document */

.Privacy-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 53px);
	display: flex;
	flex-direction: column;
}
.Privacy-welcome-container {
	width: 85%;
	color: #000001;
	margin: 50px auto;
}
.Privacy-welcome-emailLink {
	color: #000001;
	text-decoration: none;
	font-weight: bold;
}
.Privacy-welcome-container p {
	font-size: 16px;
    font-weight: 300;
    color: #000001;
	margin-top: 5px;
	line-height: 1.3;
}

.Privacy-welcome-container h1 {
	font-size: var(--media-xl-font-size);
  font-weight: 500;
  line-height: 1;
    color: #000001;
}

.Privacy-welcome-container h3 {
	font-size: 24px;
    font-weight: 700;
    color: #000001;
	margin-top: 30px;
}

.Privacy-top-para {
	margin-top: -10px;
}

.Privacy-open-link {
	margin: 0;
}
.Privacy-welcome-maintitle {
	text-align: center;
	width: 100%;
	color: #000001;
	margin-top: 50px;
	font-size: 36px;
}
.Privacy-Headerbanner-container {
    padding: 30px;
	colo: #ffffff;
	width: 100%;
  height: 365px;
  background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/08/privacy-section-ready.webp");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  align-items: center;
  border-bottom: 6px solid #062347;
  background-position: center center;
}
.Privacy-Headerbanner-textcontainer {
	display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  height: 481px;
}
.Privacy-Headerbanner-textcontainer h1 {
	font-size: var(--media-l-font-size);
  font-weight: 500;
  line-height: 1;
  margin-left: 50px;
	color: #000001;
}

.Privacy-Headerbanner-textcontainer p { 
  font-size: 1em;
  font-weight: 300;
  line-height: 1;
  margin-top: 6px;
}

@media screen and (max-width: 850px) and (min-width: 350px) {
	
	.Privacy-Headerbanner-container {
		height: 280px;
		padding: 0;
	}
	.Privacy-Headerbanner-textcontainer h1 {
		font-size: 20px;
		padding: 20px;
	}
	
	.Privacy-welcome-container p {
		font-size: 12px;
	}
	.Privacy-welcome-container h1 {
  		font-size: 23px;
	}
	.Privacy-welcome-container h3 {
  		font-size: 23px;
		font-weight: 900;
		width: 75%;
	}
	.Privacy-Headerbanner-textcontainer {
  border-left: 3px solid #ffffff;
  padding: 10px;
}
	
	
}
