/*
* {
	border: 1px solid red;
}
*/

.Pricing-main-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 53px);
	display: flex;
	flex-direction: column;
}

.pricing-headerbanner-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	background-image: 
		linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.35)),
		url("https://www.digitechsystems.com/wp-content/uploads/2024/07/pricing-section-wr.jpg");
	background-size: cover;
  	background-repeat: no-repeat;
  	height: 500px;
	background-position: center right;
	border-bottom: 6px solid #062347;
}


@media screen and ( max-width: 599px ) and ( min-width: 350px ) {
	
	   .pricing-headerbanner-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	background-image: 
		linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.65)),
		url("https://www.digitechsystems.com/wp-content/uploads/2024/07/pricing-section-wr.jpg");
	background-size: cover;
  	background-repeat: no-repeat;
  	height: 500px;
	background-position: center right;
	border-bottom: 6px solid #062347;
    }
	
 }

.title-icon-container {
	display: flex;
	flex-direction: row;
	height: 30px;
	width: 90%;
    margin: 5px auto;
	max-width: 1200px;
	align-items: center;
}

.title-icon-container h3 {
    font-size: 1.45em;
}

.p-icon-automations {
	width: 30px;
	height: 30px;
	display: flex;
	background-image: url("https://s3.us-east-1.amazonaws.com/www.info.sys.tm/uploads/Automations.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAR7573XVKWTTBXLFR/20250325/us-east-1/s3/aws4_request&X-Amz-Date=20250325T201618Z&X-Amz-Expires=604799&X-Amz-Signature=8ad96ff74a623ec5db89d220b0817dd95e1f32241710109434eb54ecae207129&X-Amz-SignedHeaders=host&response-content-disposition=inline");
	background-size: cover;
  	background-repeat: no-repeat;
	background-position: center;
	margin-left: 3px;
    margin-top: 3px;
}

.p-icon-flows {
	width: 30px;
	height: 30px;
	display: flex;
	background-image: url("https://s3.us-east-1.amazonaws.com/www.info.sys.tm/uploads/Flows.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAR7573XVKWTTBXLFR/20250325/us-east-1/s3/aws4_request&X-Amz-Date=20250325T201725Z&X-Amz-Expires=604799&X-Amz-Signature=264d50ad881a24217ed1e437603ebd65216a5cb86a7c43e8387c07564d90a0d1&X-Amz-SignedHeaders=host&response-content-disposition=inline");
	background-size: cover;
  	background-repeat: no-repeat;
	background-position: center;
	margin-left: 7px;
    
}

.p-icon-intel {
	width: 30px;
	height: 30px;
	display: flex;
	background-image: url("https://s3.us-east-1.amazonaws.com/www.info.sys.tm/uploads/Intelligence.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAR7573XVKWTTBXLFR/20250325/us-east-1/s3/aws4_request&X-Amz-Date=20250325T201755Z&X-Amz-Expires=604799&X-Amz-Signature=033d60719260dad65bc1be6b8f6ff8d44a5386b258e97926862839b130f6ee73&X-Amz-SignedHeaders=host&response-content-disposition=inline");
	background-size: cover;
  	background-repeat: no-repeat;
	background-position: center;
	margin-left: 7px;
    
}

.Pricing-main-container h1 {
	font-size: var(--media-xl-font-size);
	margin-top: 25px;
	width: 380px;
	margin: 25px auto 0 auto;
	font-weight: 500;
	line-height: 1.1;
	margin-left: 50px;
}

.Pricing-main-subtitle {
	text-align: left;
	max-width: 677px;
    margin-left: 50px;
    width: 65%;
	font-size: var(--media-m-font-size);
    font-weight: 300;
    line-height: 1.4;
    margin-top: 5px;
}

.Pricing-RPA-rightitems p {
    font-size: var(--media-s-font-size);
    line-height: 1.4;
    margin-top: 5px;
    max-width: 535px;
    width: 65%;
	margin-left: 5px;
    margin-bottom: 5px;
	margin: 5px 0px 5px 5px;
}

.Pricing-plans {
	width: 91%;
	max-width: 1200px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0px auto;
	padding-top: 50px;
}

.Pricing-plans-h5 {
	font-size: 24px;
	margin: 0 auto;
}

.Pricing-plans-p {
	font-size: 17px;
	margin: 0 auto;
}

.Pricing-plans-title {
	width: 20%;
	height: 350px;
	background-color: #C8DAF2;
}

.Pricing-plans-subcontainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin: 0 auto 10px auto;
}

.Pricing-listItem {
	display: flex;
	flex-direction: row;
}
.Pricing-plans-items {
    text-align: center;
	border: 3px solid #1263C7;
	padding: 10px 0;
	width: 48%;
	background: #D4E3F5;
	border-radius: 4px;
}
.Pricing-plans-items h4 {
    text-align: center;
/*	font-size: var(--media-s-font-size);*/
	font-weight: 600;
	font-size: 1.1em;
}
.Pricing-plans-items h3 {
    text-align: center;
	font-size: var(--media-xl-font-size);
	font-weight: 500;
	margin-bottom: 7px;
}
.Pricing-title-price {
	display: flex;
	flex-direction: row;
}
.Pricing-plans-items p {
	width: 100%;
    text-align: center;
    margin: 0 auto;
    font-weight: 300;
	font-size: .9em;
}

.Pricing-feature-container{
	width: 88%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	margin: 7px auto;
    padding: 5px;
}
.Pricing-feature-itemcontainer {
	width: 96%;
	height: 22px;
	border-bottom: 1px solid #b1b1b1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 1px 10px;
	margin: 1px 0;
	align-items: center;
}
.top-feature {
	border-top: 3px solid #1263C7;
	padding-top: 8px;
}
.bottom-feature {
	border-bottom: none;
}

.Pricing-feature-itemtext {
	width: 550px;
    font-size: var(--media-s-font-size);
    font-weight: 500;
    text-align: left;
	color: #282828;

}
.Pricing-feature-textcontainer {
/*	width: 45%;*/
	display: flex;
	justify-content: space-around;
}
.nonactive {
	color: #b1b1b1;
}
.Pricing-listItem-button {
	padding: 12px 50px;
	border-radius: 30px;
	border: none;
	margin: 7px auto;
	color: #ffffff;
	margin-top: 15px;
    margin-bottom: 7px;

}
.professional-button {
	background: #F16E12;
}
.standard-button {
	background: #1263C7;
}
.Pricing-addon {
/*	margin-top: 25px;*/
	width: 95%;
    font-size: var(--media-s-font-size);
    line-height: 1.2;
}
.Pricing-addon p {
	text-align: left;
	margin: 3px 0;
	font-size: var(--media-s-font-size);
	margin-left: 5%;
	font-weight: 500;
   color: rgb(0, 0, 1);
}

.Pricing-RPA-container {
	width: 90%;
	border: 3px solid #1263C7;
	display: none;
	flex-direction: row;
	margin: 10px auto 7px auto;
	color: #282828;
	border-radius: 4px;
	background: #D4E3F5;
}

.Pricing-RPA-rightitems {
	display: flex;
	justify-content: space-between;
}
.Pricing-RPA-left {
	width: 40%;
	display: flex;
  	flex-direction: column;
  	justify-content: center;
  	align-items: center;
}
.Pricing-RPA-left h2 {
	width: 90%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgb(0, 0, 1);
	border-bottom: 3px solid #1263C7;
	font-weight: 500;
}
.Pricing-RPA-left p {
	width: 65%;
	margin: 0 auto;
	margin-top: 15px;
	color: rgb(0, 0, 1);
	text-align: center;
}
.Pricing-RPA-right {
	width: 60%;
/*	border: 1px solid #1263C7;*/
}
.Pricing-RPA-rightitems {
	
	width: 96%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
}

.ss-automations-bt {
	border-top: 1px solid #1263C7;
    background: #e9f1fa;
}

.Pricing-RPA-righttext {
	text-align: left;
/*  	width: 77%;*/
  	margin: 15px 0 5px 25px;
  	font-size: var(--media-s-font-size);
    font-weight: 400;
  	color: rgb(0, 0, 1);
}
.bottom-rpa {
	margin-bottom: 10px;
}
.Pricing-doc-capture {
	font-size: var(--media-s-font-size);
}
.Pricing-doc-capture p {
	font-size: var(--media-s-font-size);
    width: 90%;
    margin: 0 auto 30px auto;
	font-weight: 400;
	max-width: 1200px;
}

.n-link-dec {
	text-decoration: none;
	color: #1263c7;
}

.Privacy-titleunderline {
	font-weight: bold;
}
.Privacy-line-break {
	height: 5px;
}
.Pricing-contact-container {
	width: 100%;
/*  background-color: #D4E3F5;*/
  border-top: 6px solid #062347;
  display: flex;
	margin-top: -5px;
}

.Pricing-left-textblock h2 {
	text-align: left;
	font-size: var(--media-l-font-size);
}
.Pricing-left-textblock p {
	font-size: var(--media-s-font-size);
	text-align: left;
	margin: 0;
}

.Pricing-RPA-left p {
	width: 83%;
}

.pricing-button {
	width: 95px;
	margin-top: 30px;
	margin: 0px auto;
}

.pricing-contact-button {
  margin-left: 10px;
  margin-top: 10px;
  width: 62px;
}

.pricing-page-button {
	margin-left: 44px;
	margin-buttom: 10px;
	margin-top: 10px;
}

.pricing-welcome-text {
	display: flex;
	flex-direction: column;
	text-align: left;
	justify-content: center;
	height: 481px;
}

.add-storage-p {
	font-weight: 400;
}

.Pricing-plans-container {
	width: 34%;
	border: 1px solid #1263C7;
	background: #e9f1fa;
}

.Pricing-storage-container {
	width: 50%;
	background: #e9f1fa;
}

.Pricing-storage-container p {
	width: 95%;
	margin: 0 auto;
/*	font-size: .95rem;*/
}

.Pricing-storage-container h4 {
	width: 95%;
	margin: 0 auto 5px auto;
	font-size: 1.1rem;
}

.storage-text-side {
	display: flex;
	justify-content: center;
    flex-direction: column;
	background: #D4E3F5;
	border-right: 2px solid #1263c7;
	padding: 25px 0px;
}

.ai-text-side {
	display: flex;
	justify-content: center;
    flex-direction: row;
	background: #D4E3F5;
	border-right: 2px solid #1263c7;
	width: 100%;
	border-top: 3px solid #1263C7;
}

.ai-text-side2 {
	display: flex;
	justify-content: center;
    flex-direction: row;
	background: #D4E3F5;
	border-right: 2px solid #1263c7;
	width: 100%;
}



.pp-2px-lb {
	border-right: 2px solid #1263c7;
}

.storage-full-side {
	display: flex;
	justify-content: center;
    align-items: center;
    flex-direction: column;
	width: 100%;
	padding: 15px 0;
}

.storage-full-side p {
	margin: 5px auto;
	width: 96%;
}

.storage-full-side h5 {
	font-size: 1.15em;
/*    font-weight: 500;*/
	margin: 8px auto 0 auto;
    width: 96%;
}

.Pricing-bottom-container {
	background: #D4E3F5;
	width: 100%;
    border-top: 2px solid #1263C7;
	padding: 15px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: .95rem;
	flex-direction: column;
}

.Pricing-bottom-container p {
	width: 95%;
	margin: 0 auto;
}

.Pricing-mainstorage-plans {
	display: flex;
	flex-direction: column;
    border: 3px solid #1263C7;
    width: 91%;
    margin: 0px auto 25px auto;
    border-radius: 4px;
	max-width: 1200px;
	filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.15));
}

.Pricing-subcontainer-plans {
	display: flex;
	flex-direction: column;
    width: 100%;
}

.Pricing-intelligence-container {
	border: 3px solid #1263C7;
	width: 91%;
	margin: 0px auto 15px auto;
	border-radius: 4px;
	max-width: 1200px;
}

.pp-thin-bottom {
	border-bottom: 1px solid #1263C7;
}

.pp-top-bottom {
	border-top: 3px solid #1263C7;
	border-bottom: 3px solid #1263C7;
}

.pp-top {
	border-top: 3px solid #1263C7;
}
.pp-b-o {
	border-bottom: 3px solid #1263C7;
}


.full-width-container {
	width: 59%;
	margin: 15px 0;
	padding: 0 10%;;
	display: flex;
	flex-direction: column;
	justify-content: center;
/*	align-items: center;*/
}

.pp-ai-model {
	margin: 0 0 5px 18px;
	font-size: 1.25rem;
}

.Pricing-intelligence-plans {
	display: flex;
	flex-direction: column;
    border-radius: 4px;
}

.Pricing-intel-middle {
	border-top: 3px solid #1263C7;
	border-bottom: 3px solid #1263C7;;
}

.Pricing-storage-plans {
	display: flex;
	flex-direction: row;
	border-top: none;
}

.Ai-storage-plans {
	display: flex;
	flex-direction: column;
	border-top: none;
}

.Pricing-aa-textcontainer {
	
}

.Pricing-full-container {
	display: flex;
	flex-direction: column;
	border-top: none;
}

.Pricing-add-plans {
	display: flex;
	flex-direction: row;
	border: 2px solid #1263C7;
	width: 91%;
	margin: 7px auto 0 auto;
	border-radius: 4px;
	max-width: 1200px;
}

.Pricing-line-through {
	text-decoration: line-through;
	color: red;
	margin-right: 4px;
}

.Pricing-plans-titles {
	display: flex;
	flex-direction: row;
	
	justify-content: space-between;
    align-items: center;
    width: 97%;
    margin: 0 auto;
}

.Pricing-price-container {
	width: 225px;
}

.Pricing-plans-subitems {
	background: #e9f1fa;
	display: flex;
    align-items: center;
}

.pp-text {
	padding: 0px 17px;
	height: 38px;
	font-size: 14px;
	
}

.pp-button-text {
	height: 70px;
}

.pp-top-text { 
	border-top: 1px solid #1263C7;
}

.pp-middle-text { 
	border-top: 1px solid #1263C7;
}

.pp-bold-topborder {
/*	border-top: 3px solid #1263C7;*/
}
.pp-border-right {
	border-right: 2px solid #1263c7;
}
.pp-title {
	font-weight: 500;
	padding: 8px 12px;
	background: #d4e3f5;
	display: flex;
	font-size: .88rem;
}

.pp-top-title {
	font-weight: 600;
	padding: 8px 18px;
	background: #d4e3f5;
	height: 30px;
	display: flex;
	font-size: 1rem;
	border-top: 3px solid #1263C7;
    border-bottom: 1px solid #1263C7;
}

.pp-notop-title {
	font-weight: 600;
	padding: 8px 18px;
	background: #d4e3f5;
	height: 30px;
	display: flex;
	font-size: 1rem;
    border-bottom: 1px solid #1263C7;
}

.pp-bold-title {
/*	font-size: 1rem;*/
	padding-left: 15px;
}

.pp-title-sub {
	padding: 13px 12px;
	background: #d4e3f5;
	height: 38px;
	border-bottom: 1px solid #1263C7;
	
}

.pp-title-sub p {
	font-weight: 500;
	font-size: .8rem;
}

.Pricing-addplans-mobile {
/*		display: none;*/
	}

.Pricing-button-container {
	border: 3px solid #1263C7;
	width: 89%;
	margin: 25px auto 12px auto;
	display: flex;
	flex-direction: row;
	border-radius: 4px;
    background: #D4E3F5;
	padding: 12px;
	align-items: center;
	text-align: center;
	max-width: 1180px;
}

.Pricing-button-subcontainer {
	width: 60%;
	height: 75px;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pricing-sheet-button {
	height: 25px;
	margin: 10px 0;
}


.Pricing-button-h5 {
	font-size: 16px;
	font-size: 1.3em;
    font-weight: 500;;
	margin: 0px auto;
	line-height: 1.3;
}

.t-dec {
	text-decoration: none !important;
}

.Pricing-addon-p {
	width: 44%;
	max-width: 1200px;
	margin: 4px 0 0 0;
	font-size: .9rem;
}


.pp-text-flows-botton {
	background: #d4e3f5;
	border-radius: 25px;
	padding: 8px 19px;
	border: 1px solid #1263c7;
	filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.15));
}

.Posts-textside-container {
	width: 70% !important;
}

.pp-bottom-border {
	border-bottom: 1px solid #1263C7;
}

.Price-per-col {
/*	border: 1px solid red;*/
	width: 90px;	
}

.Price-per-col-b {
/*	border: 1px solid red;*/
	width: 115px;	
}

.Price-per-col-gb {
/*	border: 1px solid red;*/
	width: 50px;	
}

.Price-attended-col {
/*	border: 1px solid red;*/
	width: 130px;
}

.Price-agent-automations {
/*	border: 1px solid red;*/
	width: 245px;
}

.Price-attended-price {
/*	border: 1px solid red;*/
	width: 150px;
}

.Price-per-disclaimer {
/*	border: 1px solid red;*/
	width: 155px;	
}

@media screen and ( max-width: 1275px ) and ( min-width: 100px ) {
	.pp-text {
		font-size: 11.5px;
	}
}

@media screen and ( max-width: 1100px ) and ( min-width: 100px ) {
	
	.pp-title {
		font-size: .9em;
	}
	.Pricing-plans-container {
		width: 34%;
	}
	
	.Pricing-button-h5 {
		font-size: 1em;
	}
	
}

@media screen and ( max-width: 1050px ) and ( min-width: 100px ) {
	
	.pp-title {
		font-size: .9em;
	}
	.Pricing-plans-container {
		width: 34%;
	}
	
}



.Pricing-astrix {
	display: flex;
	flex-direction: column;
	width: 90%;
}

.ml-text {
	margin-left: 0px;
	margin-top: 7px;
}

@media screen and ( max-width: 1155px ) and ( min-width: 100px ) {
	
	.ml-text {
		margin-left: 0px;
	}
	
}

.welcome-button {
	margin-bottom: 15px;
}

.Pricing-addon-title {
   width: 91%;
	font-size: 1.9rem;
	max-width: 1200px;
	line-height: 1.15;
	margin: 15px auto;
}

.Pricing-addon-title1 {
	font-size: 1.9rem;
	max-width: 1200px;
	line-height: 1.15;
	margin-top: 10px;
}

.style-addon-title {
	font-size: 1.3rem;
	max-width: 1200px;
}


.pp-usage-container {
	
	width: 90%;
	margin: 0 auto;
}


@media screen and ( max-width: 1100px ) and ( min-width: 100px ) {
	
	.Pricing-doc-capture h5 {
		font-size: var(--media-s-font-size);
	}
	.Pricing-main-container h1 {
		width: 50%;
		font-size: var(--media-l-font-size);
	}
	.Pricing-main-subtitle {
		width: 75%;
		line-height: 1.1;
		margin-top: 10px;
	}
	
	.ml-text {
	  margin-left: 0;
    }
	
}


@media screen and ( max-width: 1000px ) and ( min-width: 100px ) {
	
	.Pricing-plans {
		width: 90%;
	}
	
}


@media screen and ( max-width: 849px ) and ( min-width: 200px ) {
	
	.Pricing-astrix {
		width: 94%;
		font-size: 13px;
	}
	
	.Pricing-plans {
		flex-direction: column;
		align-items: center;
	}
	.Pricing-addplans-mobile {
		display: flex;
	}
	.pp-pricing-top {
		display: none;
	}

	.Pricing-plans-subcontainer {
		flex-direction: column;
		align-items: center;
		width: 95%;
	}
	
	.Pricing-addon-title {
		margin: 25px auto 11px auto;
       font-size: 1.55rem;
	}
	
	.Style-addon-title {
		
       font-size: 1rem;
	}
	
	.pricing-headerbanner-container {
		height: 385px;
	}
	
	.Pricing-plans-items {
  		width: 99%;
	}
	
	.Pricing-main-subtitle {
		width: 72%;
	}
	
	.No-bottom-margin {
		margin-bottom: 20px;
    }
	
	.Pricing-doc-capture h5 {
		font-size: var(--media-xs-font-size);
	}
	.Pricing-RPA-container {
		flex-direction: column;
	}
	.Pricing-RPA-left {
		width: 100%;
	}
	.Pricing-RPA-right {
		width: 100%;
	}
	.Pricing-RPA-left p {
		margin: 6px 0;
	}
	.Pricing-RPA-rightitems p {
		font-size: var(--media-s-font-size);
	}
	.Pricing-RPA-righttext {
		font-size: var(--media-s-font-size);
		margin: 10px 0;
		margin-left: 17px;
	}
	.Pricing-addon p {
		font-size: var(--media-xs-font-size);
    	margin: 8px 0 0 10%;
	}
	.pp-text {
		font-size: 13.5px;
	}
	.Pricing-add-plans {
		margin-bottom: 8px;
	}
	
	
}

@media screen and ( max-width: 749px ) and ( min-width: 200px ) {
	
	.Pricing-button-container {
		width: 86%;
		flex-direction: column;
        align-items: center;
        padding-top: 12px;
        justify-content: center;
		margin-top: 25px;
	}
	
	.Pricing-button-subcontainer {
		width: 80%;
		padding: 12px;
	}
	
	.Pricing-add-plans {
		flex-direction: column;
        justify-content: space-between;
        border: none !important;
		margin-bottom: -5px;
		width: 91%;
	}
	
	.Pricing-plans-container {
		width: 100%;
		border: 3px solid #1263C7;
    	margin: 5px 0;
		border-radius: 4px;
	}
	
	.Pricing-storage-plans {
		flex-direction: column;
	}
	.storage-text-side {
		width: 100%;
	}
	.Pricing-storage-container {
		width: 100%;
	}
	
	.pp-iq-title {
  		
  		border-top: 1px solid #1263C7;
	}
	.Pricing-addon-p {
    width: 90%;
    max-width: 1200px;
/*    margin-left: 17px;*/
		font-size: 15px;
   }
	
	
}



@media screen and ( max-width: 649px ) and ( min-width: 200px ) {
	
	.Pricing-plans {
		flex-direction: column;
		align-items: center;
	}
	.Pricing-main-container h1 {
        width: 64%;
		font-size: var(--media-xl-font-size);
		margin-left: 30px;
  }
	 .Pricing-main-subtitle {
    	width: 87%;
    	margin: 5px auto 10px auto;
		 font-size: var(--media-s-font-size);
  }
	.Pricing-RPA-righttext {
		margin-left: 13px;
		font-size: var(--media-xs-font-size);
		line-height: 1.3;
	}
	.Pricing-feature-itemtext {
  		width: 550px;
  		font-size: var(--media-xs-font-size);
	}
	
}

.pricing-button {
	width: 260px;
	margin: 20px 0 !important;
}

@media screen and ( max-width: 499px ) and ( min-width: 200px ) {
	
	.Pricing-plans-container {
		width: 99%;
		margin: 5px 0;
		border: 3px solid #1263C7;
        border-radius: 4px;
	}
	.Pricing-button-subcontainer {
        width: 100%;
        padding: 12px;
  }
	
	.Pricing-doc-capture h5 {
		width: 91%;
		font-size: 11px;
        margin-top: 5px;
	}
	.Pricing-button-container {
		width: 86%;
	}
	.Pricing-astrix {
		width: 87%;
	}
	.Pricing-astrix p {
		width: 87%;
	}
	.add-storage-p {
		font-size: 13px;
        margin: 6px 0px;
	}
	
}

@media screen and ( max-width: 475px ) and ( min-width: 200px ) {
	
	.Pricing-plans-titles {
		flex-direction: column;
	}
	.pp-text {
    font-size: 12px;
  }
	
	
}

.pp1 {
	width: 100%;
	height: 480px;
	justify-content: center;
  display: flex;
  flex-direction: column;
}

.pp1l {
	padding: 25px 0px;
}

.pp1 p {
	margin: 5px auto;
}

.pp-iq-title {
	padding-left: 17px ;
}

.pp-iq-title p {
	margin: 0;
}

.pp-ns {
/*	display: none;*/
}

.table-no-border {
	border-top: none;
}

.pp-no-border {
	border: 0;
	border-bottom: 1px solid #1263C7;
}

.no-top-border {
	border-top: none !important;
}
@media screen and ( max-width: 875px ) and ( min-width: 100px ) {
	.Pricing-storage-container p {

  font-size: .85rem;
}
}

@media screen and ( max-width: 750px ) and ( min-width: 100px ) {
	
  .ai-text-side2 {
    flex-direction: column;
  }
	.ai-text-side {
		flex-direction: column;
	}
	
	.pp1l {
    border-bottom: 2px solid #1263c7;
/*		height: 377px;*/
  }

	.Pricing-addon-title {
		font-size: 1.45rem;
	
	}
	
	
}
