@charset "utf-8";
/* CSS Document */

.ThanksExploreMore-container {
	width: 100%;
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 53px);
	display: flex;
	flex-direction: column;
}
.ThanksExploreMore-welcome-container {
	width: 100%;
	display: flex;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/08/thanks-background-wr.webp");
    background-size: cover;
    background-repeat: no-repeat;
	flex-direction: row;
	border-bottom: 6px solid #062347;
	height: 500px;
}
.ThanksExploreMore-welcome-subcontainer {
	width: 50%;
/*	height: 400px;*/
	display: flex;
    justify-content: center;
}
.ThanksExploreMore-welcome-leftcontainer {
	display: flex;
    justify-content: center;
	flex-direction: column;
	color: #ffffff;
	align-items: center;
}
.ThanksExploreMore-welcome-leftcontainer h2 {
	font-size: 2.25em;
    width: 60%;	
	font-weight: 500;
	line-height: 1.1;
	color: #000001;
}
.ThanksExploreMore-welcome-leftcontainer p {
	font-size: 1.2em;
  	width: 60%;
  	font-weight: 100;
  	line-height: 1.2;
	color: #000001;
}
.ThanksExploreMore-channels-container {
	width: 100%;	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #D4E3F5;
	border-bottom: 6px solid #062347;
}
/*
.ThanksExploreMore-channels-container h4 {
	width: 53%;
  	color: rgb(0, 0, 1);
  	font-size: 18px;
  	line-height: 1.1;
  	font-weight: 500;
}
*/
.ThanksExploreMore-channels-itemscontainer {
	width: 95%;	
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding: 0 0 25px 0;
}

.ThanksExploreMore-channels-item {
	width: 28%;
/*	align-items: center;	*/
	margin: 8px 0;
	background-color: #BED8F9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid #062347;
    height: 316px !important;
    margin-bottom: 15px;
}
.ThanksExploreMore-channels-subitem {
	width: 90%;
	min-height: 430px;
	margin: 5px;
	color: #062347;
	display: flex;
  	flex-direction: column;
  	justify-content: space-between;
    padding: 15px;
}
.ThanksExploreMore-channels-subitem h2 {
	color: rgb(0, 0, 1);
	font-size: 18px;
	font-weight: 500;
	width: 90%;
	margin-bottom: 3px;
}
.thanks-item-contact h4 {
	font-size: 18px;
	font-weight: 500;
	width: 90%;
	margin-bottom: 7px;
	text-align: left;
	margin-left: 0;
	color: rgb(0, 0, 1);
}
.ThanksExploreMore-channels-subitem h5 {
	font-weight: 500;
	width: 90%;
	margin-bottom: 3px;
	color: rgb(0, 0, 1);
    font-size: 18px;
}
.ThanksExploreMore-channels-subitem p {
	color: rgb(0, 0, 1);
    line-height: 1.1;
	width: 90%;
	font-size: 14px;
    font-weight: 400;
}
.thanks-sales-contact {
	font-size: 14px !important;
    font-weight: 500 !important;
	margin: 2px 0;
}
.thanks-item-top {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-small-office.png");
    background-size: cover;
    background-repeat: no-repeat;
	display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
	border-radius: 4px;
}
.thanks-item-bottom {
	margin: 8px 0;
	background-color: #BED8F9;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid #062347;
    height: 181px;
    margin-bottom: 15px;
}
.thanks-item-contact {
	height: 431px;
	background-color: #BED8F9;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid #062347;
    margin-bottom: 15px;
}
.thanks-button {
	text-decoration: none;
	color: #062347;
/*	margin: 8px 0;*/
	font-weight: 500;
	width: 95px;
} 
.thanks-contact-button {
	width: 80px;
}
.thanks-button p {
	text-decoration: none;
	color: #3C6090;;
	font-size: 1em;
	font-weight: 500;
} 
.thanks-item-contact p {
	margin-bottom: 7px;
}

.thanks-icon-img {
	margin: 0 auto 10px auto;
}

.thanks-text-container {
	height: 122px;
}

.ThanksExploreMore-meantime-title {
	text-align: center;
    width: 53%;
    color: rgb(0, 0, 1);
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
    margin: 35px 0 15px 0;
}
.thanks-text-h5 {
  font-size: 16px;
  font-weight: 500;
}
.thanks-text-p {
  font-size: 14px;
  font-weight: 400;
}

.ThanksExploreMore-img {
	
}

.sales-info {
	margin-top: 10px;
}

.Thanks-contact-container {
	height: 316px !important;
}


.Thanks-text-learnimg {
	width: 100%;
	height: 150px;
	margin-bottom: 15px;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/learn-section-wr.webp");
    background-size: cover;
    background-repeat: no-repeat;
	border-radius: 5px;
  border: 2px solid #062347;
}

.Thanks-text-howimg {
	width: 100%;
	height: 150px;
	margin-bottom: 15px;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/07/how-section-wr.webp");
    background-size: cover;
    background-repeat: no-repeat;
	border-radius: 5px;
  border: 2px solid #062347;
}


@media screen and (max-width: 985px) {
	
	.ThanksExploreMore-channels-subitem p {
      font-size: .9em;
  }

}

@media screen and (max-width: 935px) {
	
	.ThanksExploreMore-channels-itemscontainer {
  		flex-direction: column;
  }
	.ThanksExploreMore-meantime-title {
		text-align: center;
    	width: 75%; 
	}
	.thanks-button {
		width: 66px;
	}
	.thanks-item-top {
		display: none;
	}
	.ThanksExploreMore-channels-item {
		width: 87%;
		height: 170px;
		margin: 5px 2px;
	}
	.ThanksExploreMore-channels-subitem {
    	width: 91%;
    	min-height: 109px;
	}
	.thanks-item-contact {
		height: 376px;
	}
	.ThanksExploreMore-channels-itemscontainer {
		padding-bottom: 25px;
		padding-top: 0;
	}
	
}

@media screen and (max-width: 650px) {
	
	.ThanksExploreMore-welcome-container {
		display: flex;
		flex-direction: column;
	}
	.ThanksExploreMore-welcome-subcontainer {
		width: 100%;
	}
	.ThanksExploreMore-welcome-leftcontainer h2 {
        font-size: 27px !important;
		width: 80%;
/*		color: red;*/
	}
	.ThanksExploreMore-welcome-leftcontainer p {
		font-size: 15px;
		width: 80%;
	}
	.ThanksExploreMore-welcome-leftcontainer {
		height: 200px;
	}
	.ThanksExploreMore-welcome-rightcontainer {
		height: 350px;
	}
	.ThanksExploreMore-img {
  		height: 335px;
	}
	
	
}