/*text blue color: #1263C7;*/
/*orange: #F16E12;*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;800;900&display=swap');

:root {
	
	--media-xs-font-size: 0.85rem;
	--media-s-font-size: 1rem;
	--media-m-font-size: 1.25rem;
	--media-l-font-size: 1.75rem;
	--media-xl-font-size: 2rem;
	
}



* {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
}

.App {
}

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
  overflow: hidden;
}

.Padding-block {
  width: 100%;
  height: 53px;
}

.Cookie-h3 {
	font-size: 1.65em;
	font-weight: 500;}
.Cookie-p {
	font-size: .85em;
	font-weight: 300;
	line-height: 1.4;
	width: 56%;
}

.CookieConsent {
	flex-wrap: nowrap !important;
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	justify-content: space-around !important;
}
.mainButtonStyle {
	margin: 0 !important;
	width: 43% !important;
	margin-left: 50px !important;
}
.mainButtonWrapper {
    display: flex;
	margin-right: 50px;
}

.myHoveringButton {
	background: #ffffff !important;
	border-radius: 20px !important;
	padding: 9px 25px !important;
	font-weight: 500 !important;
	color: #326090 !important;
	font-size: 16px;
	border: .7px solid #141414;
	margin: 6px !important;
}
.myHoveringDeclineButton {
	background: #ffffff !important;
	border-radius: 20px !important;
    padding: 9px 25px !important; 
	font-weight: 500 !important;
	color: #326090 !important;
	font-size: 16px;
	border: .7px solid #141414;
	margin: 6px !important;
}
.myHoveringButton:hover {
	background: #dddddd !important;
}
.myHoveringDeclineButton:hover {
	background: #dddddd !important;
}


@media screen and (max-width: 850px) and (min-width: 350px) {
	.Cookie-p {
		font-size: .8em;
        line-height: 1.2
	}
	.Cookie-h3 {
		font-size: 1.35em;
        
	}
	
}

@media screen and (max-width: 750px) and (min-width: 350px) {
	
	.CookieConsent {
		flex-direction: column !important;
        padding-left: 12px !important;
		align-items: flex-start !important;
	}
	.mainButtonStyle {
		flex: 1 0 85px !important;
		margin-left: 0px !important;
		width: 89% !important;
	}
	.mainButtonWrapper {
		width: 300px !important;
	}
	
	
.Cookie-h3 {
	font-size: 22px;
    margin-left: 11px;
}

.Cookie-p {
	font-size: 12px;
    margin-left: 11px;
	width: 97% !important;
	margin-top: 3px;
	line-height: 1.35;
	font-weight: 300;
	margin-bottom: 12px;
}
	
	
	
}