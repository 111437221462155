/*form blue: #142740*/

.Resources-main-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 62px);
	display: flex;
	flex-direction: column;
	padding: 0px;
}

.Padding-block {
  width: 100%;
  height: 56px !important;
}

.Resources-welcome-container {
	width: 100%;
		height: 477px;
  display: flex;
  flex-direction: row;
  background-image: 
	  linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.35)),
	  url("https://www.digitechsystems.com/wp-content/uploads/2024/12/resources-cover-washed.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  border-bottom: 6px solid #062347;
}

@media screen and ( max-width: 599px ) and ( min-width: 350px ) {
	
	.Resources-welcome-container {
	width: 100%;
		height: 477px;
  display: flex;
  flex-direction: row;
  background-image: 
	  linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.65)),
	  url("https://www.digitechsystems.com/wp-content/uploads/2024/12/resources-cover-washed.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  border-bottom: 6px solid #062347;
}	
	
    }


.Resources-main-left {
	width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  height: 500px;
}

.Resources-main-left h1 {
	font-size: var(--media-xl-font-size);
  font-weight: 500;
  line-height: 1;
}

.Resources-main-left p {
	width: 55%;
	font-size: var(--media-m-font-size);
    font-weight: 300;
    line-height: 1.4;
}

.Resources-main-article {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #D4E3F5;
}

.Resources-article-container {
	display: flex;
	flex-direction: column;
	width: 95%;
	justify-content: space-between;
    align-items: center;
	flex-wrap: wrap;
	margin: 20px 0px;
}

.Resources-promo-subcontainer {
	width: 100%;
	background-color: #e6e6e6;
	display: flex;
    flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	padding: 15px 0;
	color: rgb(0, 0, 1);
    font-weight: 500;
}

.Resources-article-maintag {
	display: flex;
	flex-wrap: wrap;
	margin: 5px 0;
}

.Resources-article-item {
	width: 70%;
	margin: 15px 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-radius: 4px;
	border: 3px solid #062347;
    background-color: #ffffff;
}

.Resources-article-text {
	padding: 15px;
	width: 75%;
}

.Resources-article-text h2 {
	width: 80%;
	font-size: 1.75rem !important; 
/*	color: red;*/
}

.Resources-article-img {
	background-image: url('https://www.digitechsystems.com/wp-content/uploads/2024/10/micro-video-image.jpg');
	width: 25%;
	background-position: 50%;
    background-size: cover;
	height: 260px;
}

.Resources-sys {
	background-image: url('https://www.digitechsystems.com/wp-content/uploads/2024/09/systm-corp-intell-s1.png');
	width: 31%;
	background-position: 50%;
    background-size: cover;
	background-repeat: no-repeat;
	border-right: 2px solid #062347;
}

.Resources-micro {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2023/11/microservices-data-sheet-image.jpg");
    width: 31%;
	background-position: 50%;
    background-size: cover;
	background-repeat: no-repeat;
	border-right: 2px solid #062347;
}

.Resources-ds {
	background-image: url('https://madison-advisors.com/wp-content/uploads/2017/10/document_strategy_logo.jpg');
	width: 31%;
	background-position: 50%;
    background-size: cover;
	background-repeat: no-repeat;
	border-right: 2px solid #062347;
}

.Resources-mobile {
	background-image: url('https://www.digitechsystems.com/wp-content/uploads/2024/12/systm-mobile-resized.png');
	width: 31%;
	background-position: 50%;
    background-size: cover;
	border-right: 2px solid #062347;
/*	height: 26vh;*/
}

.Resources-automation {
	background-image: url('https://www.digitechsystems.com/wp-content/uploads/2025/01/mod-automation.png');
	width: 31%;
	background-position: 50%;
    background-size: cover;
	border-right: 2px solid #062347;
}

.Resources-epson {
	background-image: url('https://www.digitechsystems.com/wp-content/uploads/2025/01/epson-scanner_480.jpg');
	width: 31%;
	background-position: 50%;
    background-size: contain;
	border-right: 2px solid #062347;
	background-repeat: no-repeat;
}

.Resources-usb {
	background-image: url('https://usbusinessnews.com/wp-content/uploads/2022/07/US-BUSINESS-NEWS-LOGO-1.webp');
	width: 31%;
	background-position: 50%;
    background-size: contain;
	border-right: 2px solid #062347;
	background-repeat: no-repeat;
/*	height: 26vh;*/
}

.Resources-id4 {
	background-image: url('https://www.digitechsystems.com/wp-content/uploads/2023/10/microservices-ebook-image.png');
	width: 31%;
	background-position: 50%;
    background-size: cover;
	border-right: 2px solid #062347;
/*	height: 26vh;*/
}

.Resources-id5 {
	background-image: url('https://www.digitechsystems.com/wp-content/uploads/2018/09/process-automation-tools-iStock-823686906.jpg');
	width: 31%;
	background-position: 50%;
    background-size: cover;
	border-right: 2px solid #062347;
/*	height: 26vh;*/
}

.Resources-article-item:hover {
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
}

.Resources-article-tagcontainer {
	padding: 3px 7px;
	background: #1263C7;
	margin: 4px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
	color: #fff;
	font-size: .7rem;
}

.Resources-article-tagcontainer p {
	
	color: #ffffff;
	font-weight: 300;
}

.Resources-article-linkbutton {
	font-size: 16px;
    border-radius: 20px;
    background: #ffffff;
    color: #3C6090;
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding: 9px 23px;
    letter-spacing: 0.02em;
    text-decoration: none;
    border: .7px solid #141414;
	width: 85px;
	float: right;
	margin: 20px 15px 0 0;
}

.Resources-article-grebber {
	font-size: 1rem;
	margin: 3px 0;
}

.Resources-article-excerpt {
	font-size: .95rem;
	margin: 3px 0;
	line-height: 1.3;
}

.Resources-article-linkbutton:hover {
  background: #e2e9f2;
}

@media screen and ( max-width: 1200px ) and ( min-width: 701px ) {
	
	.Resources-article-item  {
		width: 90%;
	}
	
	
}


@media screen and ( max-width: 700px ) and ( min-width: 350px ) {
	
	.Resources-main-left {
		width: 80%;
		padding-left: 30px;
	}
	
	.Resources-main-left p {
		width: 90%;
	}
	
	.Resources-article-text h2 {
   	   width: 100%;
       font-size: 1.5rem !important;
	}
	
	.Resources-sys {
		display: none;
	}
	
	.Resources-ds {
		display: none;
	}
	
	.Resources-usb {
		display: none;
	}
	
	.Resources-mobile {
		display: none;
	}
	
	.Resources-automation {
		display: none;
	}
	
	.Resources-micro {
		display: none;
	}
	
	.Resources-article-item {
  		width: 90%;
	}
	
	.Resources-article-text {
		width: 92%;
	}
	
	
}


