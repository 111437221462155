@charset "utf-8";
/* CSS Document */

/*text blue color: #1263C7;*/
/*orange: #F16E12;*/
/*light blue circle: #AECAEC*/


.Footer {
  width: 100%;
  background-color: #1263C7;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;	
}

.Footer-subcontainer {
	display: flex;
	flex-direction: column;
	width: 90%;
}

.Footer-subcontainer p {
	font-size: 14px;
	font-weight: 400;
	margin: 3px 0;
	color: #fff;
	line-height: 1;
}

.Footer-linkcontainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.Reseller-form-items {
	width: 50%;
	height: 450px;
}

.Footer-cat-container {
	display: flex;
	flex-direction: column;
}

.Footer-contact-container {
	display: flex;
	flex-direction: column;
	color: #ffffff;
	text-align: right;
}

.Footer-contact-container a {
	color: #ffffff;
	text-decoration: none;
}

.Footer-copywrite {
	text-align: center;
	font-size: 12px !important;
    margin-top: 20px;
}

.Footer-privacy {
	font-size: 14px !important;
/*    margin-top: 1px;*/
	text-decoration: none;
	color: #ffffff;
	font-weight: 400;
}

.Footer-contact-title {
	font-weight: 500;
}

.Footer-contact-item {
	margin: 3px 0;
}

.Footer-daydate {
	font-size: 14px !important;
	margin: 2px 0;
	font-weight: 300;
	margin-top: 30px;
}

.Footer-copyright-container {
	display: flex;
	justify-content: space-between;
    align-content: center;
    margin-top: 25px;
	font-weight: 300;
	font-size: 10px;
}

.Footer-privacy-container {
	display: flex;
	width: 34%;
	justify-content: space-around;
	font-weight: 300;
	font-size: 10px;
	border: none;
}

.Footer-reseller-vr {
	height: 24px;
	border-right: 2px solid #fff;
}

.footer-info-links {
	text-decoration: underline !important;
}

.Footer-contact-daydate {
	margin-top: 5px;
}

.footer-nav {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 3px;
	color: #ffffff;
}

.Non-active {
	border: none !important;
	margin-top: 0 !important;
}

.blank-space {
	height: 11px;
	width: 3em;
	margin: 5px 0;
}
.Footer-copyright {
	line-height: 1.2;
}

.Footer-text {
	font-size: 11px;
}

@media screen and (max-width: 985px) {
	
	.Footer-subcontainer {
	  display: flex;
	  flex-direction: column;
	  width: 90%;
    }
	
	.Footer-privacy-container {
  		display: flex;
  		width: 52%;
	}
	
	.Footer-copyright {
/*		width: 37%;*/
	}
	
	.Footer-subcontainer p {
		font-size: 13px;
	}
	
	.Footer-contact-container {
/*  		width: 102px;*/
	}
	
}


@media screen and (max-width: 600px) and (min-width: 350px) {
	
	.Footer-contact-container {
		width: 50% !important;
	}
	.Footer-copyright-container {
/*		width: 25% !important;*/
	}
	.Footer {
		padding: 8px 0;
		height: 230px;
	}
	
	.Footer-privacy {
		font-size: 11px !important;
/*		margin-left: 13px;*/
	}
	 .Footer-privacy-container {
    	display: flex;
    	width: 52%;
	}
	.Footer-subcontainer p {
		font-size: 11px;
		width: 100%;
	}
	
}