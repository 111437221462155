.body {
	
}

.Posts-textside-container {
	width: 70%; 
}

.Posts-main-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 95.5vh;
	display: flex;
	flex-direction: column;
	padding: 0px;
	background: #d4e3f5;
}

.Posts-welcome-container {
	width: 100%;
	padding-left: 75px;
	
    display: flex;
    flex-direction: column;
    background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/12/resources-cover-washed.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    border-bottom: 6px solid #062347;
	justify-content: center;
	height: 400px;
}

.Posts-welcome-container h1 {
	font-size: var(--media-xl-font-size);
    font-weight: 500;
    line-height: 1.2;
	margin: 200px 0;
	width: 60%;
}

.Posts-promo-subcontainer {
	width: 100%;
	background-color: #e6e6e6;
	display: flex;
    flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 15px 0;
	color: rgb(0, 0, 1);
    font-weight: 500;
}

.Posts-body-container {
	width: 70%;
/*	max-width: 900px;*/
    background: #ffffff;
	margin: 35px auto 0px;
	padding: 15px 50px;
	border-radius: 4px;
	text-decoration: none !important;
}

.Posts-body-container h5 {
	font-weight: 400;
    font-size: 1.1rem;
}

.Posts-hr {
	margin: 15px 0px;
}

.Posts-body-container a {
	text-decoration: none !important;
	color: #1263C7;
}

.Posts-body-container p {
	font-size: var(--media-m-font-size);
    font-weight: 300;
	margin: 5px 0px;
	line-height: 1.25;
}

.Posts-body-container h2 {
	font-size: 2rem;
	margin-top: 10px;
	margin-bottom: 5px;
}

.Posts-body-container h3 {
	font-size: 1.2rem;
	font-weight: 600;
	margin-top: 30px;
}

.Posts-body-container ul li{
	margin-left: 23px;
	font-size: var(--media-m-font-size);
    font-weight: 300;
}

.Posts-plusimg-container {
	display: flex;
	width: 100%;
}

.Posts-plusimg-container p {
	width: 70%;
}

.Posts-plusimg-1 {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/12/comp-wsystem-resources.jpg");
    background-size: cover;
	background-position: center;
	width: 250px;
	margin-left: 3%;
	border: 1px solid #062347;
	height: 250px;
	border-radius: 4px;
	margin-bottom: 13px;
}

.Posts-plusimg-pr {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/systm/systm-flow.png");
    background-size: cover;
	background-position: center;
	width: 250px;
	margin-left: 3%;
	border: 1px solid #062347;
	height: 250px;
	border-radius: 4px;
	margin-bottom: 13px;
}

.Posts-plusimg-2 {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/systm_720.jpg");
    background-size: cover;
	background-position: center;
	width: 100%;
/*	margin-left: 3%;*/
	border: 1px solid #062347;
	height: 380px;
	border-radius: 4px;
	margin-bottom: 10px;
}

.Posts-plusimg2-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.Posts-plusimg2-container p {
	width: 100%;
}

.Posts-plusimg-3 {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/12/systm-mobile-resized.png");
    background-size: cover;
	background-position: center;
	width: 250px;
	margin-right: 3%;
	border: 1px solid #062347;
	border-radius: 4px;
}

.Posts-systm-mobile {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/12/systm-mobile.png");
    background-size: cover;
	background-position: center;
	width: 250px;
	margin-right: 3%;
	border: 1px solid #062347;
	border-radius: 4px;
	height: 250px;
}

.Posts-systm-pricing {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/12/sys-pricing-page-post.jpeg");
    background-size: cover;
	background-position: center;
	width: 474px;
	margin-right: 3%;
	border: 1px solid #062347;
	border-radius: 4px;
	height: 250px;
}

.Posts-systm-microserviesDS {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2023/11/Microservices-Data-Sheet.png");
    background-size: cover;
	background-position: center;
	width: 347px;
	margin-right: 3%;
	border: 1px solid #062347;
	border-radius: 4px;
	height: 450px;
}

.Posts-systm-def-cloud {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2023/10/microservices-cloud.png");
    background-size: 80%;
	background-position: top center;
	width: 265px;
	margin-right: 3%;
	border-radius: 4px;
	height: 95px;
	background-repeat: no-repeat;
	padding: 15px;
}

.Posts-definitions-hands {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2023/10/microservices-hex.jpg");
    background-size: cover;
	width: 350px;
	margin-left: 10px;
	border-radius: 4px;
	height: 190px;
	background-repeat: no-repeat;
	padding: 15px;
}

.Posts-definitions-greenbutton {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2023/10/tap-purchase.jpg");
    background-size: cover;
	width: 350px;
	margin-left: 10px;
	border-radius: 4px;
	height: 190px;
	background-repeat: no-repeat;
	padding: 15px;
}




.Posts-intro-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
}

.Posts-intro-veritcalcontainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 25px;
}

.Posts-intro-rowcontainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.Posts-definitions-rowcontainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.Posts-definitions-reverserowcontainer {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
}

.Posts-definitions-colcontainer {
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;
	width: 70%;
	margin: 5px 0px;
}

.Posts-definitions-pre {
/*	background: #e6e6e6;*/
	padding: 5px 0px;
	font-size: .8em !important;
}

.sub-s {
	font-size: .6em !important;
}

.p-ts {
	width: 72%;
	margin-right: 20px;
	margin-left: 0px;

}
.p-is {
	border: 1px solid #062347;
  border-radius: 4px;
}


.Posts-definitions-pre p {
	font-size: 1rem !important;
}

.Posts-sig {
	font-size: 1.2rem !important;
}

.Posts-pricing-rowcontainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.Posts-button {
	font-size: 16px;
  border-radius: 20px;
  background: #ffffff;
  color: #3C6090;
  font-weight: 500;
  display: flex;
  justify-content: center;
  padding: 9px 23px;
  letter-spacing: 0.02em;
  text-decoration: none;
  border: .7px solid #141414;
  width: 185px;
  float: right;
  margin: 20px 15px 15px 0;
}

.Posts-button:hover {
	background: #e2e9f2;

}


.Posts-intro-rowcontainer p {
	margin-right: 40px;
}

.Posts-intro-container p {
	width: 65%;
} 

.Posts-intro-container img {
	margin-left: 15px;
}

.Info-backup {
	font-size: .85rem !important;
	margin: 10px 0px;
}

.Posts-sub-text {
	font-size: .8rem !important;
}


@media screen and ( max-width: 700px ) and ( min-width: 350px ) {
	
	.Posts-welcome-container {
		padding-left: 30px;
	}
	
	.Posts-body-container {
		padding: 15px 25px;
	}
	
	.Posts-intro-container {
		flex-direction: column;
		align-items: flex-start;
	}
	
	.Posts-intro-container p {
		width: 100%;
	}
	
	.Info-backup {
		font-size: .6rem !important;
	}
	
}






.Posts-quote-box {
	border-left: 12px solid #e5eef9 !important;
	width: 95%;
	margin: 16px 3px;
	padding: 20px;
	line-height: 1.3;
}

.Posts-related-section {
	width: 70%;
  background: #ffffff;
  margin: 30px auto;
  padding: 15px 46px;
  border-radius: 4px;
	
}

.Posts-related-section h3 {
  font-size: 1.5rem;
  font-weight: 600;
	margin-top: 15px;
}

.Posts-related-container {
}

.Posts-related-item {
	border: 2px solid #062347 !important;
	margin: 12px 0px;
	padding: 15px 20px;
	border-radius: 4px;
}

.Posts-related-item:hover {
	background: #e5eef9;
}

.Posts-article-title {
/*	color: red;*/
}

.Posts-article-title h2 {
	font-size: 1.35rem;
	margin: 0px;
}

.Posts-article-title p {
	font-size: 1rem;
	margin: 0px;
	font-weight: 300;
}

.Posts-article-grebber {
	font-size: .75rem;
	margin: 3px 0;
}

.Posts-article-excerpt {
	font-size: .8rem;
	margin: 3px 0;
	line-height: 1.25;
}

.Posts-article-linkbutton {
	text-decoration: none;
	color: #000000;
}

.Posts-article-linkbutton:hover {
   background: #e2e9f2;
}

.Posts-article-textcontainer {
	display: flex;
	width: 100%;
	flex-direction: column;
	
}
.Posts-related-item:hover {
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
}


@media screen and ( max-width: 815px ) and ( min-width: 300px ) {
	
	.Posts-intro-rowcontainer {
		flex-direction: column-reverse;
	}
	
	.Posts-intro-rowcontainer p {
		width: 100%;
	}
	
}
