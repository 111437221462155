@charset "utf-8";
/* CSS Document */

/*text blue color: #1263C7;*/
/*orange: #F16E12;*/
/*light blue circle: #AECAEC*/

.Ebook-main-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 53px);
	display: flex;
	flex-direction: column;
}
.Ebook-main-titlecontainer {
	width: 100%;
	padding: 25px 0;
	color: #ffffff;
	background: #d4e3f5 !important;
}
.Ebook-main-container h1 {
	text-align: center;
    margin-top: 35px;
	font-size: var(--media-l-font-size);
    line-height: 1;
    margin: 23px auto 10px auto;
	width: 50%;
	font-weight: 500;
	color: #000001;
	max-width: 475px;
}
.Ebook-main-p {
	text-align: center;
	font-weight: 300;
	font-size: var(--media-s-font-size);
	width: 60%;
	margin: 0 auto;
	color: #000001;
	max-width: 750px;
}
.Ebook-listItem-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
	margin-left: 15px;
}
.Ebook-listItem-container p {
	font-size: var(--media-xs-font-size);
	margin-left: 10px;
	width: 80%;
}
.Ebook-form-container {
	display: flex;
	flex-direction: row;
}
.Ebook-formContainer-left {
	width: 50%;
    background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-circs-oncircs.png");
	background-position: center;
	background-size: 570px 570px;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: row;
	justify-content: center;
    height: 679px;
	align-items: center;
}
.Ebook-formContainer-img {
		position:relative;
/*        box-shadow:0 4px 8px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.4) inset;*/
}

.Ebook-formContainer-right {
	width: 50%;
/*	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/01/ebook-top-right2.png");*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.Ebook-form-button {
	max-width: 300px;
	background-color: #1263C7;
	color: #ffffff;
    border: none;
}

.Ebook-whatsincluded-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	border-bottom: 6px solid #062347;
}
.Ebook-whatsincluded-left {
	width: 40%;
	margin-left: 50px;
	display: flex;
	flex-direction: column;
    justify-content: space-between;
  background-size: 280px 275px;
  background-repeat: no-repeat;
  background-position: top;
}
.Ebook-whatsincluded-left h4 {
	padding-top: 138px;
	font-size: var(--media-s-font-size);
    font-weight: 500;
}
.Ebook-whatsincluded-left h5 {
	font-weight: 900;
    line-height: 1.1;
    font-size: var(--media-s-font-size);
	margin-bottom: 12px;
	margin-top: 18px;
}
.Ebook-whatsincluded-right {
	width: 50%;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-circs-oncircs.png");
    background-position: center;
    background-size: 570px 570px;
    background-repeat: no-repeat;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 20px;
}
.Ebook-promo-container {
	width: 100%;
  background-color: #1263C7;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-branded-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px 0;
	border-bottom: 6px solid #062347;
}

.Ebook-promo-subcontainer {
	width: 70%;
	background-color: #1263C7;
	color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 190px;
}
.Ebook-promo-left {
	width: 40%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 50px;
}
.Ebook-promo-subcontainer h2 {
	font-size: var(--media-m-font-size);
	font-weight: 900;
	
}
.Ebook-promo-subcontainer p {
	width: 67%;
	line-height: 1;
	font-size: var(--media-s-font-size);
	font-weight: 300;
	text-align: center;
    margin: 3px 0;
}

.Ebook-promo-subcontainer a {
	text-decoration: none;
}

.Ebook-promo-right {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	
}
.Ebook-promo-button {
	max-width: 175px;
    margin-top: 15px;
	
	border: .07px solid #141414 !important;
  font-size: var(--media-s-font-size) !important;
  border-radius: 20px !important;
  background: #ffffff !important;
  color: #3c6090 !important;
  font-weight: 700 !important;
  display: flex !important;
  justify-content: center !important;
  padding: 9px 25px !important;
  letter-spacing: 0.02em !important;
  text-decoration: none !important;
  margin-left: 10px !important;
}
.Ebook-quote-container {
	width: 100%;
	height: 160px;
	text-align: right;
	display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: center;
	padding: 38px 0px;
}
.Ebook-quote-subcontainer {
	width: 72%;
}
.Ebook-quote-subcontainer p {
	text-align: left;
	font-size: 1.1em;
	font-weight: 300;
	margin-bottom: 15px;
}
.Ebook-form-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #000001;
	padding-top: 25px;
	border-bottom: 6px solid #062347;
	background: #d4e3f5 !important;
}
.Ebook-form-container h2 {
		font-size: var(--media-l-font-size);
		line-height: 1.1;
		margin-bottom: 15px;
}
.Ebook-form-container h4 {
	font-size: var(--media-m-font-size);
    margin-bottom: 10px;
    line-height: 1.1;
	color: #000001;
	margin-left: 15px;
}
.Ebook-form-title {
	width: 45%;
	margin-left: 50px	
}
.Ebook-form-title h5 {
	font-size: var(--media-m-font-size);
	margin-top: 10%;
}
.Ebook-form-title ul {
	margin-left: 20px;
}	
.Ebook-form {
	width: 50%;
	color: #ffffff;
	margin: 20px auto;
}
.Ebook-form-items {
	width: 70%;
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}
.Ebook-form-items input {
		height: 30px;
		border: 1px solid #1263C7;
        border-radius: 3px;
        margin: 3px 0 6px 0;
		padding-left: 5px;
}
.Ebook-form form {
	margin: 0 auto;
}

.Ebook-quote-h5 {
	font-size: var(--media-m-font-size);
}
		
.Products-HK-item {
	width: 100%;
	display: flex;
	flex-direction: row;
}
.Products-HK-textcontainer {
	width: 67%;
}
.Learn-HK-img {
	border-radius: 50%;
	margin-right: 20px;
	margin-left: 20px;
}

.mktoText {
	border: none !important;
}

.Ebook-iframe-container {
    width: 99%;
    height: 505px;
	border: none !important;
}

@media screen and (max-width: 999px) and (min-width: 350px) {
	
	.Ebook-form-container {
		flex-direction: column;
	}
	
	.Ebook-iframe-container {
		width: 95%;
	}
	
	.Ebook-iframe-maincontainer {
		display: flex;
		align-items: center;
		height: 560px;
	}
	
	.Ebook-whatsincluded-container {
		flex-direction: column-reverse;
	}
	.Ebook-whatsincluded-right {
		width: 100%;
	}
	.Ebook-whatsincluded-left {
		width:  90%;
	}
	.Ebook-formContainer-right {
  	 	width: 60%;
  		background-size: contain;
  		background-repeat: no-repeat;
  		background-position: center;
  		margin-top: 15px;
	
}

	.Ebook-main-container h1 {
  		font-size: var(--media-xl-font-size);
	}
	.Ebook-main-p {
  		font-size: 15px;
  		width: 75%;
  		margin: 0 auto;
	}
	.Ebook-form-container {
  		width: 100%;
  		flex-direction: column;
	}
	.Ebook-formContainer-img {
		height: 280px;
	}
	.Ebook-formContainer-left {
		height: 378px;
        align-items: center;
		background-size: contain;
		width: 96%;
	}
	.Ebook-whatsincluded-container {
		flex-direction: column-reverse;
	} 
	.Ebook-whatsincluded-left {
        width: 95%;
		margin: 0 auto;
	}
	.Ebook-whatsincluded-right {
        width: 46%;
	    display: flex;
    	justify-content: center;
    	align-items: center;
		background-size: cover;
		margin: 0 auto;
	}
	.Ebook-whatsincluded-img {
		height: 333px;
	}
	.Ebook-promo-subcontainer h2 {
  		font-size: var(--media-xl-font-size);
	}
	.Ebook-promo-subcontainer p {
  		font-size: var(--media-s-font-size);
		line-height: 1.1;
	}
	.Ebook-whatsincluded-left h4 {
  		padding-top: 15px;
	}
	.Ebook-whatsincluded-leftsubcontainer {
		width: 96%;
		margin: 0 auto;
	}
	.Ebook-listItem-container p {
  		width: 100%;
	}
	.Ebook-form-container h4 {
  		font-size: var(--media-s-font-size);
		margin-left: 25px;
		width: 80%;
	}
	
	.Ebook-listItem-container {
		margin-left: 28px;
	}
	
}