@charset "utf-8";
/* CSS Document */

.HowMightSystmHelpYou-container {
	overflow-y: scroll !important;
	overflow-x: clip !important;
	height: 94.35vh;
    height: calc(100vh - 53px);
	display: flex;
	flex-direction: column;
}

.Padding-block {
  width: 100%;
  height: 56px;
}


.How-bigpicture-container {
	width: 100%;
	background: #D4E3F5;
	border-bottom: 6px solid #062347;
}

.HowMightSystmHelpYou-Headerbanner-container {
	width: 100%;
	height: 477px;
	background-image: 
		linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.35)),
		url("https://www.digitechsystems.com/wp-content/uploads/2024/07/how-section-wr.webp");
	background-size: cover;
	background-repeat: no-repeat;
	color: #000001;
    display: flex;
    align-items: center;
    border-bottom: 6px solid #062347;
	background-position: center center;
}


@media screen and ( max-width: 599px ) and ( min-width: 350px ) {
	
	.HowMightSystmHelpYou-Headerbanner-container {
	width: 100%;
	height: 477px;
	background-image: 
		linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.65)),
		url("https://www.digitechsystems.com/wp-content/uploads/2024/07/how-section-wr.webp");
	background-size: cover;
	background-repeat: no-repeat;
	color: #000001;
    display: flex;
    align-items: center;
    border-bottom: 6px solid #062347;
	background-position: center center;
}
	
	
    }

.HowMightSystmHelpYou-Headerbanner-textcontainer {
/*	padding: 15px;*/
	height: 480px;
	display: flex;
	flex-direction: column;
    justify-content: center;
	padding-left: 50px;
}

.HowMightSystmHelpYou-Headerbanner-textcontainer h1 {
	font-size: var(--media-xl-font-size);
    font-weight: 500;
	line-height: 1;
/*	margin-left: 50px;*/
}

.HowMightSystmHelpYou-Headerbanner-textcontainer p {
	font-size: var(--media-m-font-size);
    font-weight: 300;
    line-height: 1.4;
    margin-top: 5px;
    max-width: 535px;
    width: 80%;
	margin-bottom: 25px;
}

@media screen and ( max-width: 1200px ) and ( min-width: 800px ) {
	
	.HowMightSystmHelpYou-Headerbanner-textcontainer h2 {
		font-size: var(--media-l-font-size);
	}
	
	.HowMightSystmHelpYou-Headerbanner-textcontainer p {
		font-size: var(--media-s-font-size);
	}
	
}

.HowMightSystmHelpYou-gains-itemcontainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: 10px 0;
	width: 92%;
}

.HowMightSystmHelpYou-gains-items {
	border: 1px solid #ffffff;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	width: 29%;
	color: #ffffff;
	line-height: 1.1;
	padding: 20px 15px;
}

.HowMightSystmHelpYou-gains-items h2 {
	font-size: 1.65em;
	font-weight: 500;
}

.HowMightSystmHelpYou-gains-items h3 {
	font-size: 1.35em;
	font-weight: 500;
	margin-bottom: 5px;
}

.HowMightSystmHelpYou-gains-items p {
	font-size: 14px;
	font-weight: 300;
	overflow: auto;
	scrollbar-width: none;
}

.HowMightSystmHelpYou-gainstext {
	border: none;
	padding: 0px;
	padding: 20px 16px;
}

.HowMightSystmHelpYou-salecontact-container {
	width: 100%;
	padding: 50px 0;
	border-bottom: 6px solid #062347;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    background-image: url(https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-branded-background.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.salecontact-subcontainer {
	display: flex;
	justify-content: center;
	align-items: center;
}


.HowMightSystmHelpYou-salecontact-rightcontainer {
	width: 30%;
}

.HowMightSystmHelpYou-salecontact-container h2 {
	font-size: var(--media-l-font-size);
	color: #ffffff;
    font-weight: 500;
	text-align: center;
}

.salecontact-button {
    background: none;
    color: #F16E12;
	margin: 0;
    border: 3px solid #F16E12;
	text-decoration: none;
	width: 100px !important;
}

.HowMightSystmHelpYou-pricing-container {
	width: 100%;
	display: flex;
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/03/blue-branded-background.png");
    background-size: cover;
    background-repeat: no-repeat;
	padding: 25px 0;
    justify-content: center;
	border-bottom: 6px solid #062347;
}

.HowMightSystmHelpYou-pricing-itemcontainer {
	width: 90%;
	display: flex;
	justify-content: space-around;
}

.HowMightSystmHelpYou-pricing-items {
	width: 31%;
	display: flex;
	flex-direction: column;	
	justify-content: center;
}
.HowMightSystmHelpYou-pricing-items  h2 {
	color: #ffffff;
	font-size: 28px;
    width: 76%;
    font-weight: 500;
}
.HowMightSystmHelpYou-pricing-items  p {
	color: #ffffff;
	font-size: var(--media-s-font-size);
	width: 92%;
	font-weight: 300;
	margin-top: 5px;
	line-height: 1.3;
}
.pricing-options {
	background: #D4E3F5;
	border-radius: 4px;
}
.HowMightSystmHelpYou-pricing-link {
	text-decoration: none;
	color: #Ffffff;
	margin-top: 50px;
	font-size: .85em;
	border: 3px solid #ffffff;
    border-radius: 30px;
    padding: 12px 35px;
    width: 115px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.HowMightSystmHelpYou-pricing-link:hover {
	border: 3px solid #F16E12;
	color: #ffffff;
	background: #F16E12;
}
.HowMightSystmHelpYou-plans-items {
  text-align: center;
  padding: 10px;
}
.HowMightSystmHelpYou-plans-items h4 {
  text-align: center;
  font-size: 1.55em;
	font-weight: 500;
}
.HowMightSystmHelpYou-plans-items h3 {
    text-align: center;
    font-size: 16px;
	font-weight: 400;
	line-height: 1.1;
}
.HowMightSystmHelpYou-feature-container {
  width: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 12px auto 0 auto;
}
.HowMightSystmHelpYou-top-feature {
  border-top: 3px solid #1263C7;
  padding-top: 10px !important;
}
.HowMightSystmHelpYou-feature-itemtext {
  width: 550px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #282828;
}
.HowMightSystmHelpYou-feature-textcontainer {
  width: 45%;
  display: flex;
  justify-content: space-around;
}
.HowMightSystmHelpYou-feature-itemcontainer {
  width: 97%;
  height: 22px;
  border-bottom: 1px solid #b1b1b1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1px 10px;
  margin: 1.5px 0;
  align-items: center;
}
.HowMightSystmHelpYou-feature-itemtext {
  width: 550px;
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  color: #282828;
	
}
.bottom-feature {
  border-bottom: none;
}
.HowMightSystmHelpYou-gains-footnotes {
    padding: 0 0 10px 0;
	color: #ffffff;
	display: flex;
	align-items: center;
	font-size: .65em;
}
.HowMightSystmHelpYou-gains-innercontainer {
	list-style-type: none;
	width: 98%;
    margin: 0 auto;
}
.HowMightSystmHelpYou-gains-innercontainer ul {
	text-decoration: none;
	list-style-type: none;
	color: rgb(0, 0, 1);
	width: 90%;
}
.HowMightSystmHelpYou-gains-innercontainer ul li {
	margin: 2px 0;
}
.HowMightSystmHelpYou-gains-innercontainer ul a {
	color: rgb(0, 0, 1);
	text-decoration: none;
}
.HowMightSystmHelpYou-gains-innercontainer ul a:hover {
	color: #F16E12;
	text-decoration: none;
	font-weight: 300;
}
.HowMightSystmHelpYou-sup {
	font-size: .7em;
}
.pricing-nonactive {
		display: none;
	}

.How-items-container {
	display: flex;
	flex-direction: column;
	width: 755%;
	margin: 8px 0px;
/*	border: 1px solid red;*/
	max-width: 1000px;
/*	overflow-x: scroll;*/
}

.How-3pack-subcontainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
/*	border: 1px solid blue;*/
	margin: 8px 0;
}

.How-bigpicture-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.How-bigpicture-text h3 {
	font-weight: 500;
  font-size: var(--media-s-font-size);
  line-height: 1.3;
  margin-bottom: 3px;
	width: 100%;
}

.How-bigpicture-text p {
	font-weight: 400;
    font-size: var(--media-xs-font-size);
    line-height: 1.3;
}

.How-imgblock-a {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/nonactive-roi.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  background-position: center;
}

.How-bigpicture-a:hover {
	border: 2px solid #062347;
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2))
}

.How-bigpicture-a:Hover .How-imgblock-a {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/active-roi.png");
}


.How-imgblock-b {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/darkblue-cloud.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  background-position: center;
}

.How-bigpicture-b:hover {
	border: 2px solid #062347;
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2))
}

.How-bigpicture-b:Hover .How-imgblock-b {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/white-bg-cloud.png");
}

.How-imgblock-b1 {
	background-image: url("https://s3.us-east-1.amazonaws.com/www.info.sys.tm/uploads/how-ai-icon.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 55px;
    height: 55px;
    background-position: center;
	margin-bottom: 8px;
}

.How-bigpicture-b1:hover {
	border: 2px solid #062347;
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2))
}

.How-bigpicture-b1:Hover .How-imgblock-b1 {
	background-image: url("https://s3.us-east-1.amazonaws.com/www.info.sys.tm/uploads/how-ai-icon-active.png");
}


.How-imgblock-c {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/nonactive-rpa.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  background-position: center;
}

.How-bigpicture-c:hover {
	border: 2px solid #062347;
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2))
}

.How-bigpicture-c:Hover .How-imgblock-c {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/active-rpa.png");
}


.How-imgblock-d {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/doc-location.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  background-position: center;
}

.How-bigpicture-d:hover {
	border: 2px solid #062347;
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2))
}

.How-bigpicture-d:Hover .How-imgblock-d {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/active-doc-location.png");
}

.How-imgblock-e {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/nonactive-papers.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  background-position: center;
}

.How-bigpicture-e:hover {
	border: 2px solid #062347;
	background-color: #94C0F5;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2))
}

.How-bigpicture-e:Hover .How-imgblock-e {
	background-image: url("https://www.digitechsystems.com/wp-content/uploads/2024/09/active-papers.png");
}


.How-bigpicture-items {
	align-items: center;
  background-color: #bed8f9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  padding: 20px;
  width: 27%;
  margin: 0px 6px;
	border: 2px solid #062347;
/*	min-width: 250px;*/
}

.How-bigpicture-intro {
	width: 20%;
	margin: 10px 20px;
}

.HowMightSystmHelpYou-h3 {
	font-size: var(--media-m-font-size);
	margin-top: 12px;
}

.HowMightSystmHelpYou-p {
	margin-top: 7px;
	font-size: var(--media-s-font-size);
}

.How-bigpicture-itemcontainer {
	display: flex;
	padding: 20px 0px;
	justify-content: center;
}

.How-bigpicture-intro-hidden {
	display: none;
}

.Home-products-container {
	width: 100%;
    display: flex;
    color: #000001;
    border-bottom: 6px solid #062347;
    justify-content: center;
    align-items: center;
	padding: 25px 0px;
}


@media screen and (max-width: 1150px) and (min-width: 300px) {
	
	.How-bigpicture-intro {
		display: none;
	}
	.How-bigpicture-intro-hidden {
		display: flex;
		flex-direction: column;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0 0 0;
	}
	.How-items-container {
		width: 95%;
	}
	
}

	
@media screen and (max-width: 985px) and (min-width: 801px) {
	.HowMightSystmHelpYou-salecontact-container h2 {
		font-size: 16px;
		text-align: left;
	}
	.HowMightSystmHelpYou-pricing-link {
		border: 3px solid #ffffff;
		border-radius: 7px;
		padding: 12px 45px;
	}
	
}

@media screen and (max-width: 985px) and (min-width: 301px) {
	
	.How-items-container {
		flex-direction: column;
		align-items: center;
	}
/*
	.How-bigpicture-items {
        width: 88%;
		margin: 5px 0;
	}
*/
	
	.HowMightSystmHelpYou-p {
		margin-top: 10px;
	}
	
}

@media screen and (max-width: 800px) and (min-width: 350px) {
	.pricing-active {
		display: none;
	}
	.pricing-nonactive {
		display: flex !important;
	}
	.HowMightSystmHelpYou-Headerbanner-textcontainer {
		width: 69%;
	    max-width: 285px;
	}
	.HowMightSystmHelpYou-gains-itemcontainer {
		flex-direction: column;
		margin: 0;
		padding-bottom: 0;
	}

	.HowMightSystmHelpYou-gains-items {
		width: 90%;
		margin: 5px 0;
	}


	.HowMightSystmHelpYou-salecontact-container {
		flex-direction: column;
		padding: 25px 0;
	}
	.HowMightSystmHelpYou-salecontact-container h2 {
		font-size: 18px;
    	text-align: center;
		margin-bottom: 8px;
	}
	
	.HowMightSystmHelpYou-salecontact-leftcontainer {
  		width: 80%;
		margin-left: 0px;
	}
	
	.products-container-left {
		width: 100%;
    	height: 190px;
	}
	
	.HowMightSystmHelpYou-pricing-itemcontainer {
  		flex-direction: column;
		align-items: center;
	}
	
	.HowMightSystmHelpYou-pricing-items {
  		width: 90%;
		margin: 10px 0;
		text-align: center;
	}
	
	.HowMightSystmHelpYou-Headerbanner-container {
  		width: 100%;
  		height: 500px;
	}
	
	.HowMightSystmHelpYou-plans-items {
		margin: 5px 0;
	}
	
	.HowMightSystmHelpYou-pricing-link {
  		margin: 15px auto;
	}
	
	.HowMightSystmHelpYou-pricing-items p {
		width: 100%;
		font-size: 13px;
	}
	
	.HowMightSystmHelpYou-pricing-items h2 {
  		width: 80%;
		margin: 0 auto;
	}
	
	.Home-products-container {
		flex-direction: column-reverse;
/*		height: 472px;*/
		padding-bottom: 60px;
	}
	
	.Products-container-right {
		width: 100%;
		height: 285px;
	}
	
	.Products-container-left {
		width: 100%;
		height: 295px;
		justify-content: flex-start;
	}
	
	.Products-container-left h3 {
		margin-top: 20px;
		width: 90%;
	}
	
	.Products-sheet-img {
  		width: 87%;
   }

}

@media screen and (max-width: 625px) and (min-width: 200px) {
	
	.How-3pack-subcontainer {
		flex-direction: column;
		margin: 0;
	}
	
	.How-bigpicture-items {
		width: 85%;
		margin: 8px auto;
	}

}




.Pricing-price-container h4 {
	text-align: center;
  font-weight: 600;
  font-size: 14px;
}

.Pricing-price-container p {
	width: 74%;
    text-align: center;
    margin: 0 auto;
    font-weight: 300;
    font-size: .9em;
	color: #282828;
}

.Pricing-plans-items p {
  width: 74%;
  text-align: center;
  margin: 0 auto;
  font-weight: 300;
  font-size: .9em;
}

.how-pricing-button {
	width: 130px !important;
	margin: 25px 0;
}

.How-plans-titles {
	width: 97%;
	display: flex;
	flex-direction: row;
}

.nonactive-h5 {
	color: #808080 !important;
}


